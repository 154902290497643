import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MatSnackBar} from '@angular/material';

import {RegionService} from '../../../../auth/services/region.service';
import {TenantService} from '../../../../auth/services/tenant.service';
import {UserService} from '../../../../auth/services/user.service';
import {Region} from '../../../../auth/models/region';
import {Tenant} from '../../../../auth/models/tenant';

@Injectable()

export class TenantsService {

  availableTenants = new BehaviorSubject<Array<Tenant>>([]);
  availableTenantsFound: Array<Tenant> = [];
  selectedTenant = new BehaviorSubject<Tenant>({});
  selectedRegion = new BehaviorSubject<Region>(undefined);

  constructor(
    public snackBar: MatSnackBar,
    private tenantAPI: TenantService,
    private userService: UserService,
    private regionAPI: RegionService,
   ) {
  }

  availableTenantsSubscription = this.availableTenants.subscribe(
    (response: any) => {
      this.availableTenantsFound = response;
    });

  discardSelected() {
    this.selectedTenant.next({});
    this.selectedRegion.next(null);
    this.availableTenants.next([]);
  }

  getAvailableTenants() {
    return new Promise(
      resolve => {
        if (this.availableTenantsFound.length === 0) {
          this.tenantAPI.cgetAvailableTenant()
            .subscribe(
              response => {
                this.availableTenants.next(response);
                resolve(this.availableTenantsFound);
              },
              error => {
                if (error) {
                  this.displaySnackbarError(error.message);
                }
              }
            );
        } else {
          resolve(this.availableTenantsFound);
        }
      });
  }

  getActiveTenant(action?) {
    this.selectedRegion.next(null);
    const getLoggedInUserData = this.userService.getUserData();
    if (getLoggedInUserData) {
      if (getLoggedInUserData['tenant']) {
        this.getTenantByID(getLoggedInUserData['tenant'].id).then(
          (data: Tenant) => {
            this.selectedTenant.next(data);
            this.getRegionbyID(getLoggedInUserData['tenant'].regions, action, data);
          }
        );
      }
    }
  }

  getRegionbyID(id, action?, activeTenant?) {

    const getLoggedInUserData = this.userService.getUserData();

    if (getLoggedInUserData) {
      this.regionAPI.getUserRegion(id[0])
        .subscribe(
          (response: Region) => {
            localStorage.setItem('activeRegion', JSON.stringify(response));

            if (activeTenant) {
              localStorage.setItem('activeTenant', JSON.stringify(activeTenant));
            }
            if (action === 'onChange') {
              window.location.reload();
            }

            this.selectedRegion.next(response);
            return response;
          },
          error => {
            if (error) {
              if (error.error.code !== undefined || error.error.code !== 400) {
                if (error.error.message !== undefined || error.error.message !== '') {
                  this.displaySnackbarError(error.error.message);
                }
              }
            }
          }
        );
    } else {
      localStorage.removeItem('activeRegion');
      localStorage.removeItem('tenantRegion');
    }
  }

  displaySnackbarError(message: string) {
    this.snackBar.open(message, '', {
      panelClass: 'mat-red-500-bg'
    });
  }

  getTenantByID(id: number) {
    return new Promise(resolve => {
      this.tenantAPI.getTenant(id)
        .subscribe(
          response => {
            const activeTenant = response;
            // hardcoded logos - should be moved to dynamics in the next version
            activeTenant['logo'] = 'assets/images/logos/recolor_logo.png';

            resolve(activeTenant);
          },
          error => {
            if (error) {
              this.displaySnackbarError(error.message);
            }
          }
        );
    });
  }
}
