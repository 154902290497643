import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';

import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: fuseAnimations
})
export class ForgotPasswordComponent implements OnInit  {
  submittingRequest: boolean;
  forgotPasswordForm: FormGroup;
  forgotPasswordFormErrors: { email: {} };
  linkSent: boolean;
  showLogo: boolean;

  constructor(
    public snackBar: MatSnackBar,
    private fuseConfig: FuseConfigService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authAPI: AuthService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {

    this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape
    ]).subscribe(result => {
      this.showLogo = !result.matches;
    });

    this.fuseConfig.setConfig({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });

    this.linkSent = false;

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.forgotPasswordFormErrors = {
      email: {}
    };

    this.forgotPasswordForm.valueChanges.subscribe(() => {
      this.onForgotPasswordFormValuesChanged();
    });
  }

  onForgotPasswordFormValuesChanged() {
    for (const field in this.forgotPasswordFormErrors) {
      if (!this.forgotPasswordFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.forgotPasswordFormErrors[field] = {};

      // Get the control
      const control = this.forgotPasswordForm.get(field);

      if (control && control.dirty && !control.valid) {
        this.forgotPasswordFormErrors[field] = control.errors;
      }
    }
  }

  onSubmit() {
    this.submittingRequest = true;
    return new Promise(() => {
      this.authAPI.authForgotPasswordPost({email: this.forgotPasswordForm.value['email']})
        .subscribe(
          () => {
            this.linkSent = true;
            const message = 'Your request has been sent. Check your inbox for reset password link.';
            this.snackBar.open(message, '', {
              panelClass: 'mat-blue-500-bg'
            });
            this.router.navigate(['/auth/login']);
          },
          error => {
            this.submittingRequest = false;
            if (error.status === 422) {
              const message = 'User doesn\'t exist. Please enter a valid email address.';
              this.snackBar.open(message, '', {
                panelClass: 'mat-red-500-bg'
              });
            } else {
              const message = error.error.message;
              this.snackBar.open(message, '', {
                panelClass: 'mat-red-500-bg'
              });
            }
          }
        );
    });
  }
}
