import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {mergeMap, map, catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';

import {AuthService} from './auth.service';
import {UserService} from './user.service';

@Injectable()

export class RefreshTokenInterceptor implements HttpInterceptor {

  constructor(
    public snackBar: MatSnackBar,
    private userService: UserService,
    private router: Router,
    private authAPI: AuthService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    // authentication (Bearer) required
    let authReq = req.clone({headers: req.headers});
    if (JSON.parse(localStorage.getItem('userdata_tokens'))) {
      authReq = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('userdata_tokens'))['token'])});
    }

    return next.handle(authReq).pipe(
      catchError(error => {
        if (error.status === 401) {
          return this.refreshToken().pipe(
            mergeMap(newToken => {
              const authReqInError = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + newToken)});
              return next.handle(authReqInError); // refresh was success, resend the original request
            }));
        } else {
          return throwError(error);
        }
      })) as any;
  }

  refreshToken(): Observable<string> {
    // if tokens do not exist in local storage logout user and redirect to login page
    if (JSON.parse(localStorage.getItem('userdata_tokens'))) {
      const refreshAuth = JSON.parse(localStorage.getItem('userdata_tokens'))['refresh_token']; // get refresh token from storage
      return this.authAPI.authRefreshTokenPost({refresh_token: refreshAuth}).pipe(
        map(refreshResponse => {
          // add token to storage
          localStorage.setItem('userdata_tokens', JSON.stringify(refreshResponse));
          return refreshResponse['token']; // return the new authorization token
        }),
        catchError(
          error => {
            this.userService.logOut();
            if (error.error.code !== undefined || error.error.code !== 400) {
              if (error.error.message !== undefined || error.error.message !== '') {
                this.displaySnackbarError(error.error.message);
              }
            }
            return throwError(error);
          }
        ));
    } else {
      this.userService.logOut();
      return throwError('Token does not exist for this user.');
    }
  }

  displaySnackbarError(message: string) {
    this.openSnackbar(message, 'mat-red-500-bg');
  }

  openSnackbar(message: string, color: string) {
    this.snackBar.open(message, '', {
      panelClass: color
    });
  }
}
