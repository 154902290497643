import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';

import {BASE_PATH} from '../../shared/variables';
import {Configuration} from '../../shared/configuration';
import {ApiConfigurationService} from '../../shared/services/api-configuration.service';


@Injectable()
export class AuthService {

  protected basePath = this.apiConfiguration.basePath();
  protected apiVersion = this.apiConfiguration.apiVersion();
  protected httpOptions: object;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected httpClient: HttpClient,
    private apiConfiguration: ApiConfigurationService
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }

    this.httpOptions = {
      headers: this.apiConfiguration.setHeader(this.defaultHeaders, this.configuration),
      withCredentials: this.configuration.withCredentials
    };
  }

  /**
   * Action that sends email with reset password token
   * @param data
   */

  public authForgotPasswordPost(data: { email?: string }): Observable<{}> {
    if (data === null || data === undefined) {
      throw new Error('Required parameter data was null or undefined when calling authForgotPasswordPost.');
    }

    return this.httpClient.post<{}>(`${this.basePath}/api${this.apiVersion}auth/forgot-password`, data, this.httpOptions);
  }

  /**
   * Login
   * @param body
   */

  public authLoginPost(body: { username?: string, password?: string }): Observable<TokenResponse> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter data was null or undefined when calling authLoginPost.');
    }

    return this.httpClient.post<TokenResponse>(`${this.basePath}/api${this.apiVersion}auth/login`, body, this.httpOptions);
  }

  /**
   * Refresh token
   * @param data
   */

  public authRefreshTokenPost(data: { refresh_token?: string }): Observable<TokenResponse> {

    if (data === null || data === undefined) {
      throw new Error('Required parameter data was null or undefined when calling authRefreshTokenPost.');
    }

    return this.httpClient.post<TokenResponse>(`${this.basePath}/api${this.apiVersion}auth/refresh-token`, data, this.httpOptions);
  }

  /**
   * Action that resets users password
   * @param data
   */

  public authResetPasswordPost(data: { resetPasswordToken?: string, password?: string, repeatPassword?: string }): Observable<{}> {
    if (data === null || data === undefined) {
      throw new Error('Required parameter data was null or undefined when calling authResetPasswordPost.');
    }

    return this.httpClient.post<{}>(`${this.basePath}/api${this.apiVersion}auth/reset-password`, data, this.httpOptions);
  }
}

export interface TokenResponse {
  token?: string;
  refreshToken?: string;
}
