import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../../../shared/encoder';

import {Observable, throwError} from 'rxjs';
import {map} from 'rxjs/operators';

import {LibraryImage} from '../models/image';

import {BASE_PATH} from '../../../shared/variables';
import {Configuration} from '../../../shared/configuration';
import {ApiConfigurationService} from '../../../shared/services/api-configuration.service';
import {ApiResponse} from '../../../shared/models/apiResponse';
import {CountResponse} from '../../../shared/models/countResponse';
import {AvailableTutorials} from '../models/availableTutorials';

@Injectable()
export class ImageService {

  protected basePath = this.apiConfiguration.basePath();
  protected apiVersion = this.apiConfiguration.apiVersion();
  protected gatewayPrefix = this.apiConfiguration.gatewayPrefix();
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public queryIsRunning: boolean;
  protected httpOptions: object;

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected httpClient: HttpClient,
    private apiConfiguration: ApiConfigurationService
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }

    this.queryIsRunning = false;

    this.httpOptions = {
      headers: this.apiConfiguration.setHeader(this.defaultHeaders, this.configuration),
      withCredentials: this.configuration.withCredentials
    };
  }

  /**
   * Get all Images
   * @param region If set, will filter results by region.
   * @param category If set, will filter results by category.
   * @param tag If set, will filter results by tag.
   * @param query If set, will filter results by image name.
   * @param page Pagination page
   * @param pageSize Pagination page size
   * @param userId If set will filter results by user.
   * @param test If set will filter only results currently in testing.
   * @param published If set will filter only images that are currently published.
   * @param scheduled If set will filter only images that are currently set to be published in future.
   * @param tested If set will filter only images that are currently set to tested.
   * @param tutorial
   * @param free
   * @param is3d
   * @param isCloned
   * @param count If set will return count of fetched collection.
   */

  public cgetImage(
    region?: string,
    category?: Array<string>,
    tag?: Array<string>,
    query?: string,
    page?: number,
    pageSize?: number,
    userId?: number,
    test?: string,
    published?: boolean,
    scheduled?: boolean,
    tested?: boolean,
    tutorial?: boolean,
    free?: boolean,
    is3d?: boolean,
    isCloned?: boolean,
    count?: boolean
  ): Observable<Array<LibraryImage> | CountResponse> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (region !== undefined && region !== null) {
      queryParameters = queryParameters.set('region', <any>region);
    }
    if (category !== undefined && category !== null) {
      queryParameters = queryParameters.set('category', <any>category);
    }
    if (tag !== undefined && tag !== null) {
      queryParameters = queryParameters.set('tag', <any>tag);
    }
    if (query !== undefined && query !== null) {
      queryParameters = queryParameters.set('query', <any>query);
    }
    if (page !== undefined && (count === undefined || count === false)) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && (count === undefined || count === false)) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }
    if (userId !== undefined) {
      queryParameters = queryParameters.set('userId', <any>userId);
    }
    if (test !== undefined) {
      queryParameters = queryParameters.set('test', <any>test);
    }
    if (published !== undefined) {
      queryParameters = queryParameters.set('published', <any>published);
    }
    if (scheduled !== undefined) {
      queryParameters = queryParameters.set('scheduled', <any>scheduled);
    }
    if (tested !== undefined) {
      queryParameters = queryParameters.set('tested', <any>tested);
    }
    if (tutorial !== undefined && tutorial !== null) {
      queryParameters = queryParameters.set('isTutorial', <any>tutorial);
    }
    if (free !== undefined && free !== null) {
      queryParameters = queryParameters.set('isFree', <any>free);
    }
    if (is3d !== undefined && is3d !== null) {
      queryParameters = queryParameters.set('is3d', <any>is3d);
    }
    if (isCloned !== undefined) {
      queryParameters = queryParameters.set('isClone', <any>isCloned);
    }
    if (count !== undefined) {
      queryParameters = queryParameters.set('count', <any>count);
    }
    queryParameters = queryParameters.set('long', <any>true);

    const httpOptionsExtended = Object.assign({}, this.httpOptions);
    httpOptionsExtended['params'] = queryParameters;

    if (count !== undefined) {
      return this.httpClient.get<Array<LibraryImage>>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}image`, httpOptionsExtended);
    } else {
      return new Observable(done => {
        if (!this.queryIsRunning) {
          this.queryIsRunning = true;
          this.httpClient.get<Array<LibraryImage>>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}image`,
            httpOptionsExtended
          ).pipe(
            map(res => res)
          ).subscribe(data => {
            this.queryIsRunning = false;
            done.next(data);
            done.complete();
          });

        } else {
          // done.error('my error');
          done.complete();  // or throw an error like done.error('my error');
        }
      });
    }
  }

  /**
   * Deletes a Image
   * @param imageId ID of the Image that needs to be deleted
   */

  public deleteImage(imageId: number): Observable<ApiResponse> {
     if (imageId === null || imageId === undefined) {
      return throwError('Required parameter imageId was null or undefined when calling deleteImage.');
     }

    return this.httpClient.delete<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}image/${encodeURIComponent(String(imageId))}`, this.httpOptions);
  }

  /**
   * Get Image by ID
   * @param imageId ID (name) of the Image that needs to be fetched
   * @param long boolean, returns campaign image subfolder
   */

  public getImage(imageId: number, long?: boolean): Observable<LibraryImage> {
    if (imageId === null || imageId === undefined) {
      throw new Error('Required parameter imageId was null or undefined when calling getImage.');
    }

    return this.httpClient.get<LibraryImage>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}image/${encodeURIComponent(String(imageId))}`, this.httpOptions);
  }

  /**
   * Updates a Image
   * @param imageId ID (name) of the Image that needs to be fetched
   * @param body Image object
   */

  public patchImage(imageId: number, body: LibraryImage): Observable<ApiResponse> {
    if (imageId === null || imageId === undefined) {
       return throwError('Required parameter imageId was null or undefined when calling patchImage.');
    }
    if (body === null || body === undefined) {
     return throwError('Required parameter body was null or undefined when calling patchImage.');
    }

   return this.httpClient.patch<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}image/${encodeURIComponent(String(imageId))}`, body, this.httpOptions);
  }

  /**
   * Publishes an Image
   * @param imageId ID (name) of the Image that needs to be fetched
   */

  public imagePublish(imageId: number): Observable<ApiResponse> {
    if (imageId === null || imageId === undefined) {
      return throwError( 'Required parameter imageId was null or undefined when calling imagePublish.');
    }

    return this.httpClient.post<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}image/${encodeURIComponent(String(imageId))}/publish`, null, this.httpOptions);
  }

  /**
   * Unpublishes an Image
   * @param imageId ID (name) of the Image that needs to be fetched
   */


  public patchImageUnpublish(imageId: string): Observable<ApiResponse> {
    if (imageId === null || imageId === undefined) {
      throw new Error('Required parameter imageId was null or undefined when calling patchImageUnpublish.');
    }

    return this.httpClient.post<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}image/${encodeURIComponent(String(imageId))}/unpublish`, null, this.httpOptions);
  }

  /**
   * Creates a Image
   * @param body Image object
   */

  public postImage(body: LibraryImage): Observable<ApiResponse> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling postImage.');
    }

    return this.httpClient.post<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}image`, body, this.httpOptions);
  }

  /**
   * Clones an Image
   * @param imageId ID (name) of the Image that needs to be fetched
   */

  public imageClone(imageId: number): Observable<ApiResponse> {
    if (imageId === null || imageId === undefined) {
      return throwError( 'Required parameter imageId was null or undefined when calling imageClone.');
    }

    return this.httpClient.post<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}image/${encodeURIComponent(String(imageId))}/clone`, null, this.httpOptions);
  }

  /**
   * Get Available tutorial images
   */

  public getAvailableTutorialImages(): Observable<AvailableTutorials> {
        return this.httpClient.get<AvailableTutorials>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}image/tutorial`, this.httpOptions);
  }

}
