import {Injectable} from '@angular/core';

@Injectable()
export class PermissionsService {

  constructor() {}

  getPermissions(user) {
    let roleID: number;
    let tenantID: number ;
    let activeRole = new Array<{ id: number, label: string, permissions: object }>();
    let userRoleIndex: number;
    let userRoleObject = [];
    const userRole = [];

    const permissionList = JSON.parse(localStorage.getItem('permissions'));

    if (user.region_roles.length > 0) {
      for (let i = 0; i < user.region_roles.length; i++) {
        roleID = user.region_roles[i].role.id;
        tenantID = user.region_roles[i].region.tenant.id;
        if (user.tenant) {
          if (tenantID === user.tenant.id) {
            if (permissionList.find(x => x.id === roleID)) {
              activeRole = permissionList.find(x => x.id === roleID);
              break;
            }
          }
        }
      }
    }
    if (user.tenant_roles.length > 0) {
      for (let i = 0; i < user.tenant_roles.length; i++) {
        roleID = user.tenant_roles[i].role.id;
        tenantID = user.tenant_roles[i].tenant.id;

        if (user.tenant) {
          if (tenantID === user.tenant.id) {
            if (permissionList.find(x => x.id === roleID)) {
              activeRole = permissionList.find(x => x.id === roleID);
              break;
            }
          }
        }
      }
    }
    if (activeRole['permissions'] !== undefined) {
      userRoleObject = activeRole['permissions'];
    }


    userRoleIndex = 0;
    userRoleObject.forEach(() => {
      userRole[userRoleObject[userRoleIndex]] = true;
      userRoleIndex++;
    });

    return userRole;
  }
}
