import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {LoginComponent} from './login/login.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {SessionComponent} from './session/session.component';
import {AuthGuard} from './auth.guard';
import {UnauthenticatedComponent} from './unauthenticated/unauthenticated.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';

const authRoutes: Routes = [
  {
    path: 'auth', children: [
      {path: 'login', component: LoginComponent},
      {path: 'reset-password', component: ResetPasswordComponent},
      {
        path: 'session-expired', component: SessionComponent,
        canActivate: [AuthGuard],
        data: {
          permission: [
            'category_list',
          ]
        }
      },
      {path: 'unauthenticated', component: UnauthenticatedComponent},
      {path: 'forgot-password', component: ForgotPasswordComponent}
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(authRoutes),
  ],
  providers: [
    AuthGuard
  ],
  exports: [RouterModule]
})

export class AuthRoutingModule {}
