import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CustomHttpUrlEncodingCodec} from '../../../shared/encoder';
import {BASE_PATH} from '../../../shared/variables';
import {Configuration} from '../../../shared/configuration';
import {ApiConfigurationService} from '../../../shared/services/api-configuration.service';
import {Banner} from '../models/banner';
import {Timer} from '../models/timer';
import {CountResponse} from '../../../shared/models/countResponse';
import {ApiResponse} from '../../../shared/models/apiResponse';

@Injectable()
export class BannerService {

  protected basePath = this.apiConfiguration.basePath();
  protected apiVersion = this.apiConfiguration.apiVersion();
  protected gatewayPrefix = this.apiConfiguration.gatewayPrefix();
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected httpOptions: object;

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected httpClient: HttpClient,
    private apiConfiguration: ApiConfigurationService
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }

    this.httpOptions = {
      headers: this.apiConfiguration.setHeader(this.defaultHeaders, this.configuration),
      withCredentials: this.configuration.withCredentials
    };
  }

  /**
   * Get Banner by ID
   */

  public getBanner(bannerId: number): Observable<Banner> {
    if (bannerId === null || bannerId === undefined) {
      throw new Error('Required parameter banner ID was null or undefined when calling getBanner.');
    }

    return this.httpClient.get<Banner>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}banner/${bannerId}`, this.httpOptions);
  }

  /**
   * Post Banner
   */

  public postBanner(body: Banner): Observable<ApiResponse> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling postBanner.');
    }

    return this.httpClient.post<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}banner`, body, this.httpOptions);
  }

  /**
   * Updates a Banner
   * @param bannerId ID (name) of the Image that needs to be fetched
   * @param body Banner object
   */

  public patchBanner(bannerId: number, body: Banner): Observable<ApiResponse> {
    if (bannerId === null || bannerId === undefined) {
      throw new Error('Required parameter bannerId was null or undefined when calling patchBanner.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling patchBanner.');
    }

    return this.httpClient.patch<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}banner/${encodeURIComponent(String(bannerId))}`, body, this.httpOptions);
  }

  /**
   * Get all Banners
   * @param type Banner type
   * @param page Pagination page
   * @param pageSize Pagination page size
   * @param count Pagination maximum size
   */
  public cgetBanner(type?: number, page?: number, pageSize?: number, count?: boolean): Observable<Array<Banner> | CountResponse> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (type !== undefined) {
      queryParameters = queryParameters.set('type', <any>type);
    }
    if (page !== undefined && (count === undefined || count === false)) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && (count === undefined || count === false)) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }
    if (count !== undefined) {
      queryParameters = queryParameters.set('count', <any>count);
    }
    queryParameters = queryParameters.set('long', <any>true);

    const httpOptionsExtended = Object.assign({}, this.httpOptions);
    httpOptionsExtended['params'] = queryParameters;

    return this.httpClient.get<Array<Banner>>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}banner`, httpOptionsExtended);
  }

  /**
   * Deletes a Banner
   * @param bannerId ID of the Banner that needs to be deleted
   */

  public deleteBanner(bannerId: number): Observable<ApiResponse> {
    if (bannerId === null || bannerId === undefined) {
      throw new Error('Required parameter bannerId was null or undefined when calling deleteBanner.');
    }

    return this.httpClient.delete<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}banner/${encodeURIComponent(String(bannerId))}`, this.httpOptions);
  }

  /**
   * Test Banners by type
   */

  public testBanner(bannerType: number, body?: any): Observable<ApiResponse> {

    if (bannerType === null || bannerType === undefined) {
      throw new Error('Required parameter banner Type was null or undefined when calling testBanner.');
    }

    return this.httpClient.post<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}banner/test/${bannerType}`, body, this.httpOptions);
  }

  /**
   * Publish Banners
   */

  public publishBanner(body?: any): Observable<ApiResponse> {

    return this.httpClient.post<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}banner/publish`, body, this.httpOptions);
  }


  /**
   * Get Timer current state
   */

  public getTimer(): Observable<Array<Timer>> {

    return this.httpClient.get<Array<Timer>>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}banner/timer`, this.httpOptions);
  }

  /**
   * Patch Timer state
   */

  public patchTimer(body: Timer): Observable<ApiResponse> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling patchTimer.');
    }

    return this.httpClient.patch<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}banner/timer`, body, this.httpOptions);

  }

  /**
   * Get Banner Deeplinks
   */

  public cgetBannerDeeplinks(): Observable<ApiResponse> {

    return this.httpClient.get<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}banner/deeplink`, this.httpOptions);

  }

}
