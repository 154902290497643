import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import 'hammerjs';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';

import {fuseConfig} from './fuse-config';

import {AppComponent} from './app.component';
import {MainModule} from './main/main.module';
import {AuthModule} from './auth/auth.module';
import {AppRoutingModule} from './app-routing.module';

import {NavigationService} from './navigation/services/navigation.service';

import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {ApiConfigurationService} from './shared/services/api-configuration.service';
import {RefreshTokenInterceptor} from './auth/services/refresh-token-interceptor.service';
import {PermissionsService} from './auth/services/permissions.service';
import {RoutingState} from './shared/services/routing-state.service';
import {BasePathInterceptor} from './shared/services/base-path.interceptor.service';
import {WINDOW_PROVIDERS} from './shared/window-providers';
import {ImageService} from './main/library-images/services/image.service';
import {CategoryService} from './main/administration/library-image-categories/services/category.service';
import {TagService} from './main/library-images/services/tag.service';
import {PushNotificationsService} from './main/notifications/services/pushNotifications.service';
import {BannerService} from './main/banners/services/BannerApi.service';
import {FileUploadService} from './shared/services/fileUpload.service';
import {AuthService} from './auth/services/auth.service';
import {UsersService} from './main/administration/user-profiles/services/usersApi.service';
import {TenantService} from './auth/services/tenant.service';
import {RolesService} from './auth/services/roles.service';
import {RegionService} from './auth/services/region.service';
import {CancelRequestInterceptorService} from './shared/services/cancel-request.interceptor.service';
import {HttpCancelService} from './shared/services/http-cancel.service';
import {MatDatepickerModule} from '@angular/material';

import {MatNativeDateModule} from '@angular/material';
import { MatMomentDateModule } from "@angular/material-moment-adapter";




@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    CommonModule,
    NgIdleKeepaliveModule.forRoot(),

    // Fuse Main and Shared modules
    FuseModule.forRoot(fuseConfig),
    FuseSharedModule,

    // Custom modules
    MainModule,
    AuthModule,
    AppRoutingModule,

    MatDatepickerModule,
    MatNativeDateModule, MatMomentDateModule,




  ],
  providers: [
    NavigationService,
    RoutingState,
    AuthService,
    UsersService,
    RegionService,
    TenantService,
    RolesService,
    FileUploadService,
    ImageService,
    CategoryService,
    TagService,
    BannerService,
    PushNotificationsService,
    ApiConfigurationService,
    PermissionsService,
    HttpCancelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasePathInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CancelRequestInterceptorService,
      multi: true
    },
    WINDOW_PROVIDERS
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule {}
