import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';

import {RolesService} from '../services/roles.service';
import {AuthService} from '../services/auth.service';
import {UserService} from '../services/user.service';
import {UsersService} from '../../main/administration/user-profiles/services/usersApi.service';
import {TenantsService} from '../../main/administration/tenant-settings/services/tenants.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: fuseAnimations
})
export class LoginComponent implements OnInit {
  submittingRequest: boolean;
  loginForm: FormGroup;
  loginFormErrors: { username: [], password: [] };
  wrongLoginDetails: boolean;
  showLogo: boolean;

  constructor(
    public snackBar: MatSnackBar,
    private fuseConfig: FuseConfigService,
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private authAPI: AuthService,
    private usersAPI: UsersService,
    private rolesAPI: RolesService,
    private tenantsService: TenantsService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {

    this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape
    ]).subscribe(result => {
      this.showLogo = !result.matches;
    });

    this.fuseConfig.setConfig({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none',
      }
    });

    this.wrongLoginDetails = false;

    this.loginFormErrors = {
      username: [],
      password: []
    };

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required]
    });

    this.loginForm.valueChanges.subscribe(() => {
      this.onLoginFormValuesChanged();
    });
  }


  onLoginFormValuesChanged() {
    for (const field in this.loginFormErrors) {
      if (!this.loginFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.loginFormErrors[field] = {};

      // Get the control
      const control = this.loginForm.get(field);

      if (control && control.dirty && !control.valid) {
        this.loginFormErrors[field] = control.errors;
      }
    }
  }

  onLogin() {
    this.submittingRequest = true;
    return new Promise(resolve => {
      this.tenantsService.discardSelected();
      this.userService.logOut();

      this.authAPI.authLoginPost({
        username: this.loginForm.value['username'],
        password: this.loginForm.value['password']
      })
        .subscribe(
          response => {
            localStorage.setItem('userdata_tokens', JSON.stringify(response));

            this.usersAPI.profileGet()
              .subscribe(
                subscribeResponse => {
                  this.userService.logIn(subscribeResponse);
                  this.router.navigate(['welcome'])
                    .then(
                      () => {
                        // get user roles
                        this.rolesAPI.rolesGet()
                          .subscribe(
                            result => {
                              localStorage.setItem('permissions', JSON.stringify(result));
                            }, error => {
                              if (error) {
                                this.snackBar.open(error.message, '', {
                                  panelClass: 'mat-red-500-bg'
                                });
                              }
                            });
                      }
                    );
                },
                () => {
                  this.submittingRequest = false;
                  this.loginForm.reset();
                  this.wrongLoginDetails = true;
                }
              );
          },
          () => {
            this.submittingRequest = false;
            this.loginForm.controls['password'].reset();
            this.wrongLoginDetails = true;
          }
        );
    });
  }
}
