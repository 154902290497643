import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Tenant} from '../models/tenant';
import {Idle} from '@ng-idle/core';

@Injectable()
export class UserService {

  constructor(
    private router: Router,
    private idle: Idle
  ) {}

  getUserData() {
    return JSON.parse(localStorage.getItem('userdata'));
  }

  logOut(noRedirect?: boolean) {
    localStorage.removeItem('userdata');
    localStorage.removeItem('userdata_tokens');
    localStorage.removeItem('permissions');
    localStorage.removeItem('activeTenant');
    localStorage.removeItem('activeRegion');
    localStorage.removeItem('availableTenants');
    if (!noRedirect) {
      this.router.navigate(['/auth/login']);
    }
  }

  logIn(loggedInData: object, activeTenant?: Tenant) {
    if (activeTenant){
      loggedInData['tenant'] =  activeTenant;
    }
    localStorage.setItem('userdata', JSON.stringify(loggedInData));

    // if user has timed out, reset idle watch
    if (!this.idle.isRunning()) {
      this.idle.watch();
    }
  }
}
