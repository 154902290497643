import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

import {BASE_PATH} from '../../shared/variables';
import {Configuration} from '../../shared/configuration';
import {ApiResponse} from '../../shared/models/apiResponse';
import {ApiConfigurationService} from '../../shared/services/api-configuration.service';
import {CustomHttpUrlEncodingCodec} from '../../shared/encoder';

import {Region} from '../models/region';

@Injectable()
export class RegionService {

  protected basePath = this.apiConfiguration.basePath();
  protected apiVersion = this.apiConfiguration.apiVersion();
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected httpOptions: object;

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected httpClient: HttpClient,
    private apiConfiguration: ApiConfigurationService
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }

    this.httpOptions = {
      headers: this.apiConfiguration.setHeader(this.defaultHeaders, this.configuration),
      withCredentials: this.configuration.withCredentials
    };
  }

  /**
   * Get all regions
   * @param active If set to true, will fetch only &#39;active&#39; Region.
   * @param tenant If set, will filter by Tenant.
   */

  public cgetRegion(active?: string, tenant?: string): Observable<Array<Region>> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (active !== undefined) {
      queryParameters = queryParameters.set('active', active);
    }
    if (tenant !== undefined) {
      queryParameters = queryParameters.set('tenant', tenant);
    }

    const httpOptionsExtended = Object.assign({}, this.httpOptions);
    httpOptionsExtended['params'] = queryParameters;

    return this.httpClient.get<Array<Region>>(`${this.basePath}/tenant${this.apiVersion}region`, httpOptionsExtended);
  }

  /**
   * Get Region by ID
   * @param regionId ID of the Region that needs to be fetched
   */

  public getRegion(regionId: number): Observable<Region> {
    if (regionId === null || regionId === undefined) {
      throw new Error('Required parameter regionId was null or undefined when calling getRegion.');
    }

    return this.httpClient.get<Region>(`${this.basePath}/tenant${this.apiVersion}region/${encodeURIComponent(String(regionId))}`, this.httpOptions);
  }

  /**
   * Get Region by ID - open to all
   * @param regionId ID of the Region that needs to be fetched
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUserRegion(regionId: number, observe: any = 'body', reportProgress: boolean = false): Observable<{}> {
    if (regionId === null || regionId === undefined) {
      throw new Error('Required parameter regionId was null or undefined when calling getRegion.');
    }

    return this.httpClient.get<{}>(`${this.basePath}/api${this.apiVersion}admin/region/${encodeURIComponent(String(regionId))}`, this.httpOptions);
  }

  /**
   * Change Banner Type
   * @param regionId Region Id
   * @param bannerType Region type
   * @param includeBannerWithTimer Region set true to get response with timer
   */

  public patchBannerType(regionId: number, bannerType: number, includeBannerWithTimer?: boolean): Observable<ApiResponse> {
    if (regionId === null || regionId === undefined) {
      throw new Error('Required parameter regionId was null or undefined when calling patchBannerType.');
    }
    if (bannerType === null || bannerType === undefined) {
      throw new Error('Required parameter bannerType was null or undefined when calling patchBannerType.');
    }
    if (includeBannerWithTimer === null || includeBannerWithTimer === undefined) {
      includeBannerWithTimer = false;
    }

    const body = {includeBannerWithTimer: includeBannerWithTimer, bannerType: bannerType};

    return this.httpClient.patch<ApiResponse>(`${this.basePath}/tenant${this.apiVersion}region/${encodeURIComponent(String(regionId))}`, body, this.httpOptions);
  }

}
