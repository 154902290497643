import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../../../shared/encoder';

import {Observable} from 'rxjs';

import {Tag} from '../models/tag';

import {BASE_PATH} from '../../../shared/variables';
import {Configuration} from '../../../shared/configuration';
import {ApiConfigurationService} from '../../../shared/services/api-configuration.service';
import {ApiResponse} from '../../../shared/models/apiResponse';


@Injectable()
export class TagService {

  protected basePath = this.apiConfiguration.basePath();
  protected apiVersion = this.apiConfiguration.apiVersion();
  protected gatewayPrefix = this.apiConfiguration.gatewayPrefix();
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected httpOptions: object;

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected httpClient: HttpClient,
    private apiConfiguration: ApiConfigurationService
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }

    this.httpOptions = {
      headers: this.apiConfiguration.setHeader(this.defaultHeaders, this.configuration),
      withCredentials: this.configuration.withCredentials
    };
  }

  /**
   * Get all Tags
   * @param region If set, will filter results by region.
   * @param page Pagination page
   * @param pageSize Pagination page size
   * @param query Returns only tags matching the query (used for autocomplete).
   */


  public cgetTag(region?: number, page?: number, pageSize?: number, query?: string): Observable<Array<Tag>> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (region !== undefined && region !== null) {
      queryParameters = queryParameters.set('region', <any>region);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }
    if (query !== undefined && query !== null) {
      queryParameters = queryParameters.set('query', <any>query);
    }

    const httpOptionsExtended = Object.assign({}, this.httpOptions);
    httpOptionsExtended['params'] = queryParameters;

    return this.httpClient.get<Array<Tag>>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}tag`, httpOptionsExtended);
  }

  /**
   * Get Tag by ID
   * @param tagId ID (name) of the Tag that needs to be fetched
   */

  public getTag(tagId: number): Observable<Tag> {
    if (tagId === null || tagId === undefined) {
      throw new Error('Required parameter tagId was null or undefined when calling getTag.');
    }

    return this.httpClient.get<Tag>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}tag/${encodeURIComponent(String(tagId))}`, this.httpOptions);
  }

  /**
   * Creates a Tag
   * @param body Tag object
   */

  public postTag(body: Tag): Observable<ApiResponse> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling postTag.');
    }

    return this.httpClient.post<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}tag`, body, this.httpOptions);
  }

}
