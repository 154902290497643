import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';

import {Roles} from '../models/roles';

import {BASE_PATH} from '../../shared/variables';
import {Configuration} from '../../shared/configuration';
import {ApiConfigurationService} from '../../shared/services/api-configuration.service';


@Injectable()
export class RolesService {

  protected basePath = this.apiConfiguration.basePath();
  protected apiVersion = this.apiConfiguration.apiVersion();
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected httpOptions: object;

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected httpClient: HttpClient,
    private apiConfiguration: ApiConfigurationService
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }

    this.httpOptions = {
      headers: this.apiConfiguration.setHeader(this.defaultHeaders, this.configuration),
      withCredentials: this.configuration.withCredentials
    };
  }

  /**
   * List of all user roles (filtered by region)
   */

  public rolesGet(): Observable<Roles> {
    return this.httpClient.get<Roles>(`${this.basePath}/api${this.apiVersion}admin/role`, this.httpOptions);
  }

}
