import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterTab, RouterTabComponent, RouterTabItem} from './router-tab/router-tab.component';
import {RouterModule} from '@angular/router';
import {MatTabsModule} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatTabsModule
  ],
  declarations: [
    RouterTabComponent,
    RouterTabItem,
    RouterTab
  ],
  exports: [
    RouterTabComponent,
    RouterTabItem,
    RouterTab
  ]
})
export class RouterTabModule {}
