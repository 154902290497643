import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable()
export class BasePathInterceptor implements HttpInterceptor {
  activeTenant: string;
  activeRegion: string;
  subdomain: string;
  protocol: string;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.activeTenant = '';
    this.activeRegion = '';
    this.subdomain = '';
    this.protocol = localStorage.getItem('protocol');
    const headers = req.headers;
    let apiReq;

    if (headers.get('isAws') !== 'true') {
      const swaggerHeaders = headers.get('useSwagger');
      const libraryApiHeaders = headers.get('isLibraryApi');
      const commentsApiHeaders = headers.get('commentsApi');
      const versionHeaders = headers.get('versionCheck');

      if (swaggerHeaders !== 'true') {

        if (JSON.parse(localStorage.getItem('activeTenant')) === null && JSON.parse(localStorage.getItem('activeRegion')) === null) {
          this.subdomain = '';
          this.activeTenant = '';
          this.activeRegion = '';
        }

        if (JSON.parse(localStorage.getItem('activeTenant'))) {
          this.activeTenant = JSON.parse(localStorage.getItem('activeTenant')).subdomain;
        }

        if (JSON.parse(localStorage.getItem('activeRegion'))) {
          this.activeRegion = JSON.parse(localStorage.getItem('activeRegion')).subdomain;
        }

        if (this.activeTenant !== '' && this.activeRegion !== '') {
          this.subdomain = `${this.activeRegion}.${this.activeTenant}.`;
        }

        if (versionHeaders === 'true') {
          apiReq = req.clone({url: `${req.url}`});
        } else {
          apiReq = req.clone({url: `${this.protocol}//${this.subdomain}${environment.apiUrl}${req.url}`});
        }
        if (libraryApiHeaders === 'true') {
          apiReq = req.clone({url: `${req.url}`});
          apiReq = req.clone({headers: headers.delete('isLibraryApi')});
        }
        if (commentsApiHeaders === 'true') {
          apiReq = req.clone({url: `${req.url}`});
          apiReq = req.clone({headers: headers.delete('commentsApi')});
        }
      } else {
          apiReq = req.clone({url: `http://api.mock.recolor.codecacao.com${req.url}`});
          // TODO: uncomment line below if you use localhost for development with Swagger Mock API (make this automatic)
          // apiReq = req.clone({url: `http://127.0.0.1:8000${req.url}`});
      }
    } else {
      apiReq = req.clone({headers: headers.delete('Authorization').delete('isAws')});
    }
      return next.handle(apiReq);
  }
}
