import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from '../auth/auth.guard';

const mainRoutes: Routes = [
  {
    path: '',
    redirectTo: 'images/sandbox',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: '', children: [
      {
        path: 'welcome',
        loadChildren: './landing-page/landing-page.module#LandingPageModule',
      },
      {
        path: '',
        loadChildren: './administration/administration.module#AdministrationModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'images',
        loadChildren: './library-images/library-images.module#LibraryImagesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'image-reorder',
        loadChildren: './image-reorder/image-reorder.module#ImageReorderModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'banners',
        loadChildren: './banners/banners.module#BannersModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'bundles',
        loadChildren: './bundles/bundles.module#BundlesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'content-popup',
        loadChildren: './content-popup/content-popup.module#ContentPopupModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'notifications',
        loadChildren: './notifications/notifications.module#NotificationsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'in-app',
        loadChildren: './in-app-users/in-app-users.module#InAppUsersModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'mobile-apps',
        loadChildren: './mobile-apps/mobile-apps.module#MobileAppsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'moderation-tool',
        loadChildren: './moderation-tool/moderation-tool.module#ModerationToolModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'not-found',
        loadChildren: './page-not-found/page-not-found.module#PageNotFoundModule',
        canActivate: [AuthGuard]
      }
    ]
  }

];

@NgModule({
  imports: [
    [RouterModule.forChild(mainRoutes)]
  ],
  providers: [AuthGuard],
  exports: [RouterModule]
})
export class MainRoutingModule {}
