import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';

import {BASE_PATH} from '../../shared/variables';
import {Configuration} from '../../shared/configuration';
import {ApiConfigurationService} from '../../shared/services/api-configuration.service';
import {CustomHttpUrlEncodingCodec} from '../../shared/encoder';

import {Tenant} from '../models/tenant';

@Injectable()
export class TenantService {

  protected basePath = this.apiConfiguration.basePath();
  protected apiVersion = this.apiConfiguration.apiVersion();
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected httpOptions: object;


  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected httpClient: HttpClient,
    private apiConfiguration: ApiConfigurationService
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }

    this.httpOptions = {
      headers: this.apiConfiguration.setHeader(this.defaultHeaders, this.configuration),
      withCredentials: this.configuration.withCredentials
    };
  }

  /**
   * Get all tenants
   * @param active If set to true, will fetch only &#39;active&#39; Tenants.
   */

  public cgetTenant(active?: string): Observable<Array<Tenant>> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (active !== undefined) {
      queryParameters = queryParameters.set('active', active);
    }

    const httpOptionsExtended = Object.assign({}, this.httpOptions);
    httpOptionsExtended['params'] = queryParameters;

    return this.httpClient.get<Array<Tenant>>(`${this.basePath}/tenant${this.apiVersion}tenant`, httpOptionsExtended);
  }

  /**
   * Get tenant by ID
   * @param tenantId ID of the Tenant that needs to be fetched
   */

  public getTenant(tenantId: number): Observable<Tenant> {
    if (tenantId === null || tenantId === undefined) {
      throw new Error('Required parameter tenantId was null or undefined when calling getTenant.');
    }

    return this.httpClient.get<Tenant>(`${this.basePath}/tenant${this.apiVersion}tenant/${encodeURIComponent(String(tenantId))}`, this.httpOptions);
  }

  /**
   * Get all available tenants
   */

  public cgetAvailableTenant(active?: string): Observable<Array<Tenant>> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (active !== undefined) {
      queryParameters = queryParameters.set('active', active);
    }

    const httpOptionsExtended = Object.assign({}, this.httpOptions);
    httpOptionsExtended['params'] = queryParameters;

    return this.httpClient.get<Array<Tenant>>(`${this.basePath}/api${this.apiVersion}admin/tenant`, httpOptionsExtended);
  }
}
