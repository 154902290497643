import {Injectable} from '@angular/core';

@Injectable()
export class ApiConfigurationService {

  constructor() {
  }

  basePath() {
    return '';
  }

  apiVersion() {
    return '/v1/';
  }

  gatewayPrefix() {
    return '/admin';
  }

  setHeader(headers, configuration, consumes?) {
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];

    const httpHeaderAcceptSelected: string | undefined = configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    if (consumes) {
      const httpContentTypeSelected: string | undefined = configuration.selectHeaderContentType(consumes);
      if (httpContentTypeSelected !== undefined) {
        headers = headers.set('Content-Type', httpContentTypeSelected);
      }
    }

    return headers;
  }

}
