import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnInit,
  OnDestroy,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {Subscription} from 'rxjs';

import {FuseConfigService} from '@fuse/services/config.service';
import {TenantsService} from './administration/tenant-settings/services/tenants.service';
import {VersionCheckService} from '../shared/services/version-check.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit, OnDestroy {
  onConfigChanged: Subscription;
  fuseSettings: any;
  @HostBinding('attr.fuse-layout-mode') layoutMode;
  resetBrowser: boolean;

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private fuseConfig: FuseConfigService,
    private platform: Platform,
    private versionCheckService: VersionCheckService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.fuseSettings = this.fuseConfig.config;

    this.onConfigChanged =
      this.fuseConfig.onConfigChanged.subscribe(
        (newSettings) => {
          setTimeout(() => {
            this.fuseSettings = newSettings;
            this.layoutMode = this.fuseSettings.layout.mode;
          }, 0);
        });

    if (this.platform.ANDROID || this.platform.IOS) {
      this.document.body.className += ' is-mobile';
    }

    this.versionCheckService.versionCheckBehaviour.subscribe(
      (response: boolean) => {
        this.resetBrowser = response;
      }
    );

    this.initVersionCheck();
  }

  ngOnDestroy() {
    this.onConfigChanged.unsubscribe();
  }

  private initVersionCheck(frequency = 1000 * 60 * 30) {
    setInterval(() => {
      this.versionCheckService.initVersionCheck(environment.versionCheckURL);
    }, frequency);
  }
}
