import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-browser-reload',
  templateUrl: './browser-reload.component.html',
  styleUrls: ['./browser-reload.component.scss']
})
export class BrowserReloadComponent implements OnInit {
  browserReloadText: string;

  constructor() { }

  ngOnInit() {
    this.browserReloadText = 'The new version of Stroke has been deployed. The browser needs to be reloaded to enable latest changes.';
  }

  onBrowserReload() {
    location.reload();
  }

}
