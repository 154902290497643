import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
import {HttpCancelService} from './http-cancel.service';
import {takeUntil} from 'rxjs/operators';

@Injectable()
export class CancelRequestInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private httpCancelService: HttpCancelService
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Cancel pending calls
        this.httpCancelService.cancelPendingRequests();
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const categoryImagesRequestRegex = new RegExp(/category\/[0-9]{0,8}\/images/);

    // if on images reorder section
    if (this.router.url.includes('image-reorder/category/') && req.url.match(categoryImagesRequestRegex)) {
      return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
      } else {
      return next.handle(req);
    }
   }
}
