import {ErrorStateMatcher} from '@angular/material';
import {FormControl, FormGroupDirective, NgForm} from '@angular/forms';

/** Display errors on form load - when control is invalid, untouched and already has value in it (edit forms). */
export class OnLoadErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && !(control.untouched && 
      (!control.value || control.value === null || control.value === '' || control.value === undefined)));
  }
}
