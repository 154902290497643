import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiConfigurationService} from './api-configuration.service';
import {Configuration} from '../configuration';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class VersionCheckService {
  protected httpOptions: object;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  // this will be replaced by actual hash post-build.js
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

  versionCheckBehaviour: BehaviorSubject<boolean>;

  constructor(
    private httpClient: HttpClient,
    private apiConfiguration: ApiConfigurationService
  ) {
    this.httpOptions = {
      headers: this.apiConfiguration.setHeader(this.defaultHeaders, this.configuration),
      withCredentials: this.configuration.withCredentials
    };

    this.versionCheckBehaviour = new BehaviorSubject(false);
  }

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   */
  public initVersionCheck(url) {
    this.checkVersion(url);
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url) {
    // timestamp these requests to invalidate caches
    this.httpOptions['headers'] = this.httpOptions['headers'].set('versionCheck', 'true');

    this.httpClient.get(url + '?t=' + new Date().getTime(),
      this.httpOptions)
      .subscribe(
        (response: any) => {
          const hash = response.hash;
          const hashChanged = this.hasHashChanged(this.currentHash, hash);

          // If new version, do something
          if (hashChanged) {
            // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
            // for an example:
            // location.reload();
            this.versionCheckBehaviour.next(true);
          }
          // store the new hash so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          this.currentHash = hash;
        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentHash, newHash) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }

    return currentHash !== newHash;
  }
}
