import {NgModule} from '@angular/core';
import {MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSnackBarModule} from '@angular/material';
import {FuseSharedModule} from '../../@fuse/shared.module';

import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {LoginComponent} from './login/login.component';

import {SessionComponent} from './session/session.component';
import {AuthRoutingModule} from './auth-routing.module';
import {UnauthenticatedComponent} from './unauthenticated/unauthenticated.component';

import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';


@NgModule({
  declarations: [
    SessionComponent,
    ResetPasswordComponent,
    LoginComponent,
    UnauthenticatedComponent,
    ForgotPasswordComponent,
  ],
  imports: [
    AuthRoutingModule,
    NgIdleKeepaliveModule.forRoot(),
    // FUSE & Materials modules
    MatButtonModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FuseSharedModule
  ]
})

export class AuthModule {}
