import {Injectable} from '@angular/core';
import {UserService} from '../../auth/services/user.service';
import {PermissionsService} from '../../auth/services/permissions.service';
import {FuseSidebarService} from '../../../@fuse/components/sidebar/sidebar.service';
import {environment} from '../../../environments/environment';


@Injectable()
export class NavigationService {
  administrationPages: Array<any> = [];
  libraryPages: Array<any> = [];
  bannerPages: {};
  bundlesPages: {};
  contentPopupPages: {};
  notificationPages: {};
  userManagementPages: Array<any> = [];
  navigation: Array<any> = [];
  moderationPages: Array<any> = [];
  mobileAppsPages: {};

  constructor(
    private user: UserService,
    private permissionsService: PermissionsService,
    private sidebarService: FuseSidebarService
  ) {
  }

  rolesDefinition(rolesGroup: object) {
    const user = this.user.getUserData();
    const userRole = this.permissionsService.getPermissions(user);

    if (!user.super_admin) {
      for (let i = 0; i < Object.keys(rolesGroup).length; i++) {
        if (userRole[rolesGroup[i]]) {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  onNavigationCreate() {
    const user = this.user.getUserData();
    if (user) {
      this.administrationPages = [];
      this.libraryPages = [];
      this.navigation = [];
      this.bannerPages = [];
      this.bundlesPages = [];
      this.contentPopupPages = [];
      this.notificationPages = [];
      this.moderationPages = [];
      this.userManagementPages = [];
      this.mobileAppsPages = [];


      let administration = false;
      if (this.rolesDefinition(['tenant_add', 'tenant_edit', 'tenant_delete', 'user_add', 'user_edit', 'user_delete', 'category_add', 'category_edit', 'category_delete'])) {
        this.navigation.push({
          'id': 'navbar_admin_menu',
          'title': 'Admin menu',
          'translate': 'NAV.ADMIN_MENU.TITLE',
          'type': 'group',
          'children': []
        });

        // TO BE ADDED ON NEW VERSION
        if (this.rolesDefinition(['tenant_add', 'tenant_edit', 'tenant_delete'])) {
          this.administrationPages.push({
            'id': 'navbar_tenant_settings',
            'title': 'Tenant settings',
            'translate': 'NAV.TENANT_SETTINGS.TITLE',
            'type': 'collapse',
            'icon': 'settings',
            'children': [
              {
                'id': 'navbar_banner_settings',
                'title': 'Banner settings',
                'translate': 'NAV.TENANT_SETTINGS.BANNER.TITLE',
                'type': 'item',
                'icon': 'filter',
                'url': 'tenant/banner-settings',
                'function': () => {
                  this.toggleSidebarOpened('navbar');
                }
              }
            ]
          });
        }

        if (this.rolesDefinition(['user_add', 'user_edit', 'user_delete', 'category_add', 'category_edit', 'category_delete'])) {
          this.administrationPages.push({
            'id': 'navbar_administration',
            'title': 'Administration',
            'translate': 'NAV.ADMINISTRATION',
            'type': 'collapse',
            'icon': 'create',
            'children': []
          });

          const lastItem = this.administrationPages.length - 1;
          if (this.rolesDefinition(['user_add', 'user_edit', 'user_delete'])) {
            this.administrationPages[lastItem].children.push({
              'id': 'navbar_users',
              'title': 'Users',
              'translate': 'NAV.USERS.TITLE',
              'type': 'item',
              'icon': 'group',
              'url': '/users',
              'function': () => {
                this.toggleSidebarOpened('navbar');
              }
            });
          }
          if (this.rolesDefinition(['category_add', 'category_edit', 'category_delete'])) {
            this.administrationPages[lastItem].children.push({
              'id': 'navbar_library_image_categories',
              'title': 'Library image categories',
              'translate': 'NAV.IMAGES.CATEGORIES.TITLE',
              'type': 'item',
              'icon': 'folder_special',
              'url': '/categories',
              'function': () => {
                this.toggleSidebarOpened('navbar');
              }
            });
          }
        }
        administration = true;
        this.navigation[0].children = this.administrationPages;
      }

      if (this.rolesDefinition(
        [
          'image_add',
          'image_edit',
          'image_publish',
          'image_delete',
          'image_unpublish',
          'banner_add',
          'banner_edit',
          'banner_delete',
          'notification_add',
          'notification_edit',
          'notification_delete',
          'product_list',
          'product_view',
          'content_popup_list',
          'content_popup_view',
          'content_popup_add',
          'content_popup_edit',
          'content_popup_deeplink',
          'content_popup_delete',
          'category-bundle_list',
          'category-bundle_view',
          'category-bundle_edit',
          'category-bundle_s3_presigned',
        ])
      ) {

        this.navigation.push({
          'id': 'navbar_pages_menu',
          'title': 'Pages menu',
          'translate': 'NAV.PAGES_MENU.TITLE',
          'type': 'group',
          'children': [
            {
              'id': 'navbar_library_images',
              'title': 'Library images',
              'translate': 'NAV.IMAGES.TITLE',
              'type': 'collapse',
              'icon': 'perm_media',
              'children': []
            }
          ]
        });
      }

      if (this.rolesDefinition(['image_add', 'image_edit', 'image_delete'])) {
        this.libraryPages.push({
          'id': 'navbar_sandbox',
          'title': 'Sandbox',
          'translate': 'NAV.IMAGES.SANDBOX.TITLE',
          'type': 'item',
          'icon': 'add_photo_alternate',
          'url': '/images/sandbox',
          'function': () => {
            this.toggleSidebarOpened('navbar');
          }
        });
      }
      if (this.rolesDefinition(['image_add', 'image_edit', 'image_delete']) && this.rolesDefinition(['image_publish'])) {
        this.libraryPages.push({
          'id': 'navbar_awaiting_approwal',
          'title': 'Awaiting approval',
          'translate': 'NAV.IMAGES.AWAITING.TITLE',
          'type': 'item',
          'icon': 'pause',
          'url': '/images/awaiting',
          'function': () => {
            this.toggleSidebarOpened('navbar');
          }
        });
      }

      // Add & Edit illustration buttons are added to navigation, but are hidden with CSS. This way the Library images section won't collapse when you navigate to add/edit
      if (this.rolesDefinition(['image_add'])) {
        this.libraryPages.push({
          'id': 'add-illustration-hidden',
          'title': 'Add new Illustration',
          'translate': 'NAV.IMAGES.NEW-ILLUSTRATION.TITLE',
          'type': 'item',
          'url': '/images/new'
        });
      }
      if (this.rolesDefinition(['image_add', 'image_edit', 'image_publish', 'image_unpublish', 'image_delete'])) {
        this.libraryPages.push({
          'id': 'edit-illustration-hidden',
          'title': 'Edit Illustration',
          'translate': 'NAV.IMAGES.EDIT-ILLUSTRATION.TITLE',
          'type': 'item',
          'url': '/images/edit/'
        });
      }
      if (this.rolesDefinition(['image_add', 'image_edit', 'image_publish', 'image_unpublish', 'image_delete'])) {
        this.libraryPages.push({
          'id': 'navbar_published',
          'title': 'Published',
          'translate': 'NAV.IMAGES.PUBLISHED.TITLE',
          'type': 'item',
          'icon': 'check_box',
          'url': '/images/published',
          'function': () => {
            this.toggleSidebarOpened('navbar');
          }
        });
      }

      // Image reorder should be added to same group as other image actions
      if (this.rolesDefinition(['image_publish'])) {
        this.libraryPages.push({
          'id': 'navbar_reorder',
          'title': 'Image reorder',
          'translate': 'NAV.IMAGES.REORDER.TITLE',
          'type': 'item',
          'icon': 'shuffle',
          'url': '/image-reorder',
          'function': () => {
            this.toggleSidebarOpened('navbar');
          }
        });
      }

      if (this.rolesDefinition(['banner_add', 'banner_edit', 'banner_delete'])) {
        this.bannerPages = {
          'id': 'navbar_banners',
          'title': 'Banners',
          'translate': 'NAV.BANNERS.TITLE',
          'type': 'item',
          'icon': 'picture_in_picture',
          'url': '/banners',
          'function': () => {
            this.toggleSidebarOpened('navbar');
          }
        };
      }

      // TODO: define bundles roles
       if (this.rolesDefinition([
         'product_list',
         'product_view',
         'category-bundle_list',
         'category-bundle_view',
         'category-bundle_edit',
         'category-bundle_s3_presigned'
       ])) {
        this.bundlesPages = {
          'id': 'navbar_bundles',
          'title': 'Bundles',
          'translate': 'NAV.BUNDLES.TITLE',
          'type': 'item',
          'icon': 'collections',
          'url': '/bundles',
          'function': () => {
            this.toggleSidebarOpened('navbar');
          }
        };
      }

      if (this.rolesDefinition([
        'content_popup_list',
        'content_popup_view',
        'content_popup_add',
        'content_popup_edit',
        'content_popup_deeplink',
        'content_popup_delete'])
      ) {
        this.contentPopupPages = {
          'id': 'navbar_contentPopup',
          'title': 'Content popup',
          'translate': 'NAV.CONTENT-POPUP.TITLE',
          'type': 'item',
          'icon': 'bookmarks',
          'url': '/content-popup',
          'function': () => {
            this.toggleSidebarOpened('navbar');
          }
        };
      }

      if (this.rolesDefinition(['notification_add', 'notification_edit', 'notification_delete'])) {
        this.notificationPages = {
          'id': 'navbar_notifications',
          'title': 'Push notifications',
          'translate': 'NAV.NOTIFICATIONS.TITLE',
          'type': 'item',
          'icon': 'feedback',
          'url': '/notifications',
          'function': () => {
            this.toggleSidebarOpened('navbar');
          }
        };
      }

      if (this.rolesDefinition([environment.apiVersion + '_user_view'])) {
        this.userManagementPages.push({
          'id': 'navbar_in-app-users',
          'title': 'In-app users',
          'translate': 'NAV.IN-APP-USERS.USERS.TITLE',
          'type': 'item',
          'icon': 'supervised_user_circle',
          'url': '/in-app/users',
          'function': () => {
            this.toggleSidebarOpened('navbar');
          }
        });
      }
      if (this.rolesDefinition([
        environment.apiVersion + '_transaction_view',
        environment.apiVersion + '_transaction_revert',
        environment.apiVersion + '_transaction_self_list'
      ])) {
        this.userManagementPages.push({
          'id': 'navbar_action-history',
          'title': 'Admin action history',
          'translate': 'NAV.IN-APP-USERS.ACTION-HISTORY.TITLE',
          'type': 'item',
          'icon': 'history',
          'url': '/in-app/action-history',
          'function': () => {
            this.toggleSidebarOpened('navbar');
          }
        });
      }
      if (this.rolesDefinition([
        environment.apiVersion + '_user_view',
        environment.apiVersion + '_transaction_view',
        environment.apiVersion + '_transaction_revert',
        environment.apiVersion + '_transaction_self_list'
      ])) {
        this.navigation.push({
          'id': 'navbar_single_user_management',
          'title': 'In-app user handling',
          'translate': 'NAV.IN-APP-USERS.TITLE',
          'type': 'group',
          'children': this.userManagementPages
        });
      }

      if (this.rolesDefinition([
        environment.apiVersion + '_moderation_image_list',
        environment.apiVersion + '_moderation_report_list',
      ])) {
        this.moderationPages.push({
            'id': 'navbar_moderation_tool',
            'title': 'Moderation tool',
            'translate': 'NAV.MODERATION.TITLE',
            'type': 'item',
            'icon': 'warning',
            'url': '/moderation-tool/tools',
            'function': () => {
              this.toggleSidebarOpened('navbar');
            }
          },
          {
            'id': 'navbar_comments',
            'title': 'Comments',
            'translate': 'NAV.MODERATION.COMMENTS.TITLE',
            'icon': 'comment',
            'type': 'collapse',
              'children': [
                {
                  'id': 'navbar_comments_all',
                  'type': 'item',
                  'title': 'All',
                  'url': '/moderation-tool/comments/all',
                  'translate': 'NAV.MODERATION.COMMENTS.ALL',
                  'icon': 'comment',
                  'function': () => {
                    this.toggleSidebarOpened('navbar');
                  },
                },
                {
                  'id': 'navbar_comments_hidden',
                  'type': 'item',
                  'title': 'Hidden',
                  'url': '/moderation-tool/comments/hidden',
                  'translate': 'NAV.MODERATION.COMMENTS.HIDDEN',
                  'icon': 'block',
                  'function': () => {
                    this.toggleSidebarOpened('navbar');
                  },
                }
              ]
          },
            {
          'id': 'navbar_web_purify',
          'title': 'Web Purify',
          'translate': 'NAV.MODERATION.WEB-PURIFY.TITLE',
          'type': 'item',
          'icon': 'healing',
          'url': '/moderation-tool/purified',
          'function': () => {
            this.toggleSidebarOpened('navbar');
          }
        });
      }

      if (this.rolesDefinition([environment.apiVersion + '_moderation_feed_list'])) {
        this.moderationPages.push({
          'id': 'navbar_live_feed',
          'title': 'Live feed',
          'translate': 'NAV.MODERATION.LIVE-FEED.TITLE',
          'type': 'item',
          'icon': 'live_tv',
          'url': '/moderation-tool/live-feed',
          'function': () => {
            this.toggleSidebarOpened('navbar');
          }
        });
      }

      if (this.rolesDefinition(['search_list', 'filter_list', 'effect_list', 'palette_list', 'outline_list'])) { // TODO: Permissions to be decided
        this.moderationPages.push({
          'id': 'navbar_gallery_search',
          'title': 'Gallery search',
          'translate': 'NAV.MODERATION.GALLERY-SEARCH.TITLE',
          'type': 'item',
          'icon': 'image_search',
          'url': '/moderation-tool/gallery-search',
          'function': () => {
            this.toggleSidebarOpened('navbar');
          }
        });
      }

      if (this.rolesDefinition(
        [
          environment.apiVersion + '_moderation_image_list',
          environment.apiVersion + '_moderation_report_list',
          environment.apiVersion + '_moderation_feed_list',
          'search_list', 'filter_list', 'effect_list', 'palette_list', 'outline_list'
        ])
      ) {
        this.navigation.push({
          'id': 'navbar_moderation_tool',
          'title': 'Moderation tool',
          'translate': 'NAV.MODERATION.TITLE',
          'type': 'group',
          'icon': 'warning',
          'children': this.moderationPages
        });
      }

      if (this.rolesDefinition(['image_add', 'image_edit', 'image_delete'])) { // ['mobile_app_add', 'mobile_app_edit', 'mobile_app_delete']
        this.mobileAppsPages = {
          'id': 'mobile_apps',
          'title': 'Mobile apps versions',
          'translate': 'NAV.MOBILE-APPS-VERSIONS.TITLE',
          'type': 'item',
          'icon': 'smartphone',
          'url': '/mobile-apps',
          'function': () => {
            this.toggleSidebarOpened('navbar');
          }
        };
      }

      if (this.libraryPages.length > 0) {
        let otherPagesNavPost: any;
        if (administration) {
          otherPagesNavPost = this.navigation[1];
        } else {
          otherPagesNavPost = this.navigation[0];
        }
        otherPagesNavPost.children[0].children = this.libraryPages;
        otherPagesNavPost.children.push(this.bannerPages);
        otherPagesNavPost.children.push(this.bundlesPages);
        otherPagesNavPost.children.push(this.contentPopupPages);
        otherPagesNavPost.children.push(this.notificationPages);
        otherPagesNavPost.children.push(this.userManagementPages);
        otherPagesNavPost.children.push(this.mobileAppsPages);
      }

      return this.navigation;
    }
  }

  toggleSidebarOpened(key) {
    this.sidebarService.getSidebar(key).toggleOpen();
  }
}
