


export const environment = {
  production: true,
  hmr: false,
  apiUrl: 'prod.api-gateway.recolor.com',
  apiVersion: 'v0',
  versionCheckURL: '/version.json',
  environmentUrlHost: 'www.recolor.com'
};




/*
export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'test-api-gateway.development.recolor.com',
  apiVersion: 'v0',
  versionCheckURL: '/version.json',
  environmentUrlHost: 'www.recolor.com'
};
*/




