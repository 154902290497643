import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-nav-vertical-group',
  templateUrl: './nav-vertical-group.component.html',
  styleUrls: ['./nav-vertical-group.component.scss']
})
export class NavVerticalGroupComponent implements OnInit {
  @HostBinding('class') classes: string;
  @Input() item: any;

  constructor() {}

  ngOnInit() {
    this.classes = 'nav-group nav-item';
  }
}
