import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-nav-vertical-item',
  templateUrl: './nav-vertical-item.component.html',
  styleUrls: ['./nav-vertical-item.component.scss']
})
export class NavVerticalItemComponent implements OnInit {
  @HostBinding('class') classes: string;
  @Input() item: any;

  constructor() {}

  ngOnInit(): void {
    this.classes = 'nav-item';
  }
}
