import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {MatButtonModule, MatIconModule, MatMenuModule} from '@angular/material';

import {FuseSharedModule} from '@fuse/shared.module';
import {FuseNavigationModule} from '@fuse/components';
import {AppNavbarComponent} from 'app/main/navbar/navbar.component';
import {NavVerticalCollapseComponent} from './vertical/nav-collapse/nav-vertical-collapse.component';
import {NavVerticalGroupComponent} from './vertical/nav-group/nav-vertical-group.component';
import {NavVerticalItemComponent} from './vertical/nav-item/nav-vertical-item.component';
import {UserService} from '../../auth/services/user.service';

@NgModule({
  declarations: [
    AppNavbarComponent,
    NavVerticalCollapseComponent,
    NavVerticalGroupComponent,
    NavVerticalItemComponent
  ],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    FuseSharedModule,
    FuseNavigationModule
  ],
  providers: [
    UserService
  ],
  exports: [
    AppNavbarComponent
  ]
})
export class AppNavbarModule {}
