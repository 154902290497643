import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';

import {locale as navigationEnglish} from './navigation/i18n/en';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {Router} from '@angular/router';
import {Keepalive} from '@ng-idle/keepalive';
import {RoutingState} from './shared/services/routing-state.service';
import {WINDOW} from './shared/window-providers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  lastPing?: Date;

  constructor(
    private translate: TranslateService,
    private fuseSplashScreen: FuseSplashScreenService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private routingState: RoutingState,
    @Inject(WINDOW) private window: Window
  ) {}

  ngOnInit(): void {
    localStorage.setItem('protocol', this.window.location.protocol);

    this.routingState.loadRouting();

    // Add languages
    this.translate.addLangs(['en']);

    // Set the default language
    this.translate.setDefaultLang('en');

    // Set the navigation translations
    this.fuseTranslationLoader.loadTranslations(navigationEnglish);

    // Use a language
    this.translate.use('en');
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(36000);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      if (this.router.url !== '/auth/session-expired') {
        sessionStorage.setItem('last-visited', this.router.url);
        this.router.navigate(['/auth/session-expired']);
      }
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
    });

    this.idle.watch();
  }

}
