import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {MatSnackBar} from '@angular/material';
import {ActivatedRoute, Params} from '@angular/router';

import {AuthService} from '../services/auth.service';
import {UserService} from '../services/user.service';
import {TenantsService} from '../../main/administration/tenant-settings/services/tenants.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: fuseAnimations
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  resetPasswordFormErrors: { code: {}, password: {}, passwordConfirm: {} };
  passwordReset: boolean;
  predefinedCode: string;
  showLogo: boolean;
  submittingRequest: boolean;

  constructor(
    public snackBar: MatSnackBar,
    private fuseConfig: FuseConfigService,
    private formBuilder: FormBuilder,
    private authAPI: AuthService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private tenantsService: TenantsService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape
    ]).subscribe(result => {
      this.showLogo = !result.matches;
    });

    this.fuseConfig.setConfig({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });

    this.resetPasswordFormErrors = {
      code: {},
      password: {},
      passwordConfirm: {}
    };

    this.passwordReset = false;

    this.predefinedCode = '';

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.predefinedCode = params['code'];
    });

    this.resetPasswordForm = this.formBuilder.group({
      code: [this.predefinedCode, [Validators.required]],
      password: ['', Validators.required],
      passwordConfirm: ['', [Validators.required, confirmPassword]]
    });

    this.resetPasswordForm.valueChanges.subscribe(() => {
      this.onResetPasswordFormValuesChanged();
    });
  }

  onResetPasswordFormValuesChanged() {
    for (const field in this.resetPasswordFormErrors) {
      if (!this.resetPasswordFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.resetPasswordFormErrors[field] = {};

      // Get the control
      const control = this.resetPasswordForm.get(field);

      if (control && control.dirty && !control.valid) {
        this.resetPasswordFormErrors[field] = control.errors;
      }
    }
  }

  onReset() {
    this.submittingRequest = true;
    const passwordReset = {
      confirmation_token: this.resetPasswordForm.value['code'],
      password: this.resetPasswordForm.value['password'],
      repeat_password: this.resetPasswordForm.value['passwordConfirm']
    };

    this.authAPI.authResetPasswordPost(passwordReset)
      .subscribe(
        () => {
          this.passwordReset = true;
        },
        error => {
          this.submittingRequest = false;
          const message = error.error.message;
          this.snackBar.open(message, '', {
            panelClass: 'mat-red-500-bg'
          });
        }
      );
  }

  onLogout() {
    this.tenantsService.discardSelected();
    this.userService.logOut();
  }
}

function confirmPassword(control: AbstractControl) {
  if (!control.parent || !control) {
    return;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return;
  }

  if (passwordConfirm.value === '') {
    return;
  }

  if (password.value !== passwordConfirm.value) {
    return {
      passwordsNotMatch: true
    };
  }
}
