import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';

import {NewUser} from '../models/newUser';
import {User} from '../models/user';
import {Users} from '../models/users';

import {BASE_PATH} from '../../../../shared/variables';
import {Configuration} from '../../../../shared/configuration';
import {ApiConfigurationService} from '../../../../shared/services/api-configuration.service';
import {ApiResponse} from '../../../../shared/models/apiResponse';
import {CustomHttpUrlEncodingCodec} from 'app/shared/encoder';
import {CountResponse} from 'app/shared/models/countResponse';


@Injectable()
export class UsersService {

  protected basePath = this.apiConfiguration.basePath();
  protected apiVersion = this.apiConfiguration.apiVersion();
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected httpOptions: object;

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected httpClient: HttpClient,
    private apiConfiguration: ApiConfigurationService
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }

    this.httpOptions = {
      headers: this.apiConfiguration.setHeader(this.defaultHeaders, this.configuration),
      withCredentials: this.configuration.withCredentials
    };
  }

  /**
   * User list
   */

  public usersGet(page?: number, pageSize?: number, count?: boolean, username?: string,
                  email?: string, enabled?: boolean): Observable<Users | CountResponse> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    if (page !== undefined && (count === undefined || count === false)) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && (count === undefined || count === false)) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }
    if (count !== undefined) {
      queryParameters = queryParameters.set('count', <any>count);
    }
    if (username !== undefined) {
      queryParameters = queryParameters.set('username', <any>username);
    }
    if (email !== undefined) {
      queryParameters = queryParameters.set('email', <any>email);
    }
    if (enabled !== undefined) {
      queryParameters = queryParameters.set('enabled', <any>enabled);
    }

    const httpOptionsExtended = Object.assign({}, this.httpOptions);
    httpOptionsExtended['params'] = queryParameters;

    return this.httpClient.get<Users>(`${this.basePath}/api${this.apiVersion}admin/user`, httpOptionsExtended);
  }

  /**
   * Add new user
   * @param data
   */

  public userPost(data: NewUser): Observable<User> {
    if (data === null || data === undefined) {
      throw new Error('Required parameter data was null or undefined when calling userPost.');
    }

    return this.httpClient.post<User>(`${this.basePath}/api${this.apiVersion}admin/user`, data, this.httpOptions);
  }

  /**
   * Get profile
   * Returns profile data of currently logged in user
   */

  public profileGet(): Observable<NewUser> {
    return this.httpClient.get<NewUser>(`${this.basePath}/api${this.apiVersion}admin/profile`, this.httpOptions);
  }

  /**
   * Edit profile
   * Updates profile data of currently logged in user
   * @param data
   */

  public profilePatch(data: NewUser): Observable<ApiResponse> {

    if (data === null || data === undefined) {
      throw new Error('Required parameter data was null or undefined when calling profilePatch.');
    }

    return this.httpClient.patch<ApiResponse>(`${this.basePath}/api${this.apiVersion}admin/profile`, data, this.httpOptions);
  }

  /**
   * Delete user
   * @param userId User ID
   */


  public userDelete(userId: number): Observable<ApiResponse> {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling userDelete.');
    }

    return this.httpClient.post<ApiResponse>(`${this.basePath}/api${this.apiVersion}admin/user/${encodeURIComponent(String(userId))}/disable`, this.httpOptions);
  }

  /**
   * Activate user
   * @param userId User ID
   */


  public userActivate(userId: number): Observable<ApiResponse> {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling userActivate.');
    }

    return this.httpClient.post<ApiResponse>(`${this.basePath}/api${this.apiVersion}admin/user/${encodeURIComponent(String(userId))}/enable`, this.httpOptions);
  }

  /**
   * Edit user
   * @param userId User ID
   * @param data
   */

  public userPatch(userId: number, data: NewUser): Observable<ApiResponse> {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling userPatch.');
    }
    if (data === null || data === undefined) {
      throw new Error('Required parameter data was null or undefined when calling userPatch.');
    }

    return this.httpClient.patch<ApiResponse>(`${this.basePath}/api${this.apiVersion}admin/user/${encodeURIComponent(String(userId))}`, data, this.httpOptions);
  }

  /**
   * Add tenant role to user
   * @param userId User ID
   * @param role Role object
   */

  public userTenantRolePost(userId: number, role: object): Observable<ApiResponse> {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling userTenantRolePost.');
    }
    if (role === null || role === undefined) {
      throw new Error('Required parameter role was null or undefined when calling userTenantRolePost.');
    }

    return this.httpClient.post<ApiResponse>(`${this.basePath}/api${this.apiVersion}admin/user/${encodeURIComponent(String(userId))}/tenant-roles`, role, this.httpOptions);
  }

  /**
   * Remove tenant role from user
   * @param userId User ID
   * @param roleId Role ID
   */

  public userTenantRoleDelete(userId: number, roleId: number): Observable<ApiResponse> {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling userTenantRoleDelete.');
    }
    if (roleId === null || roleId === undefined) {
      throw new Error('Required parameter roleId was null or undefined when calling userTenantRoleDelete.');
    }

    return this.httpClient.delete<ApiResponse>(
      `${this.basePath}/api${this.apiVersion}admin/user/${encodeURIComponent(String(userId))}/tenant-roles/${encodeURIComponent(String(roleId))}`,
      this.httpOptions
    );
  }

  /**
   * Add region role to user
   * @param userId User ID
   * @param role Role object
   */

  public userRegionRolePost(userId: number, role: object): Observable<ApiResponse> {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling userRegionRolePost.');
    }
    if (role === null || role === undefined) {
      throw new Error('Required parameter role was null or undefined when calling userRegionRolePost.');
    }

    return this.httpClient.post<ApiResponse>(`${this.basePath}/api${this.apiVersion}admin/user/${encodeURIComponent(String(userId))}/region-roles`, role, this.httpOptions);
  }

  /**
   * Remove region role from user
   * @param userId User ID
   * @param roleId Role ID
   */

  public userRegionRoleDelete(userId: number, roleId: number): Observable<ApiResponse> {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling userRegionRoleDelete.');
    }
    if (roleId === null || roleId === undefined) {
      throw new Error('Required parameter roleId was null or undefined when calling userRegionRoleDelete.');
    }

    return this.httpClient.delete<ApiResponse>(
      `${this.basePath}/api${this.apiVersion}admin/user/${encodeURIComponent(String(userId))}/region-roles/${encodeURIComponent(String(roleId))}`,
      this.httpOptions
    );
  }

}
