import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../../../../shared/encoder';

import {Observable} from 'rxjs';

import {BASE_PATH} from '../../../../shared/variables';
import {Configuration} from '../../../../shared/configuration';
import {ApiConfigurationService} from '../../../../shared/services/api-configuration.service';
import {ApiResponse} from '../../../../shared/models/apiResponse';

import {Category} from '../categories.model';

@Injectable()
export class CategoryService {

  protected basePath = this.apiConfiguration.basePath();
  protected apiVersion = this.apiConfiguration.apiVersion();
  protected gatewayPrefix = this.apiConfiguration.gatewayPrefix();
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected httpOptions: object;

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected httpClient: HttpClient,
    private apiConfiguration: ApiConfigurationService
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }

    this.httpOptions = {
      headers: this.apiConfiguration.setHeader(this.defaultHeaders, this.configuration),
      withCredentials: this.configuration.withCredentials
    };
  }

  /**
   * Get all Categories
   * @param region If set, will filter results by region.
   * @param page Pagination page
   * @param pageSize Pagination page size
   * @param query Returns only tags matching the query (used for autocomplete).
   */

  public cgetCategory(region?: number, page?: number, pageSize?: number, nonImage?: boolean, query?: string): Observable<Array<Category>> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (region !== undefined && region !== null) {
      queryParameters = queryParameters.set('region', <any>region);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }
    if (nonImage !== undefined) {
      queryParameters = queryParameters.set('nonImage', <any>nonImage);
    }
    if (query !== undefined && query !== null) {
      queryParameters = queryParameters.set('query', <string>query);
    }

    const httpOptionsExtended = Object.assign({}, this.httpOptions);
    httpOptionsExtended['params'] = queryParameters;

    return this.httpClient.get<Array<Category>>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}category`, httpOptionsExtended);
  }

  /**
   * Deletes a Category
   * @param categoryId ID (name) of the Category that needs to be fetched
   */

  public deleteCategory(categoryId: string): Observable<ApiResponse> {
    if (categoryId === null || categoryId === undefined) {
      throw new Error('Required parameter categoryId was null or undefined when calling deleteCategory.');
    }

    return this.httpClient.delete<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}category/${encodeURIComponent(String(categoryId))}`, this.httpOptions);
  }

  /**
   * Get Category by ID
   * @param categoryId ID (name) of the Category that needs to be fetched
   */

  public getCategory(categoryId: number): Observable<Category> {
    if (categoryId === null || categoryId === undefined) {
      throw new Error('Required parameter categoryId was null or undefined when calling getCategory.');
    }

    return this.httpClient.get<Category>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}category/${encodeURIComponent(String(categoryId))}`, this.httpOptions);
  }

  /**
   * Updates a Category
   * @param categoryId ID (name) of the Category that needs to be fetched
   * @param body Category object
   */

  public patchCategory(categoryId: string, body: Category): Observable<ApiResponse> {
    if (categoryId === null || categoryId === undefined) {
      throw new Error('Required parameter categoryId was null or undefined when calling patchCategory.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling patchCategory.');
    }

    return this.httpClient.patch<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}category/${encodeURIComponent(String(categoryId))}`, body, this.httpOptions);
  }

  /**
   * Creates a Category
   * @param body Category object
   */

  public postCategory(body: Category): Observable<ApiResponse> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling postCategory.');
    }

    return this.httpClient.post<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}category`, body, this.httpOptions);
  }

}
