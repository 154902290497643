import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {
  MatSidenavModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatDialogModule,
  MatButtonModule,
  ErrorStateMatcher
} from '@angular/material';

import {FuseSharedModule} from '@fuse/shared.module';
import {FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';

import {MainRoutingModule} from './main-routing.module';
import {AppContentModule} from './content/content.module';
import {AppNavbarModule} from 'app/main/navbar/navbar.module';
import {ConfirmDialogModule} from '../shared/confirm-dialog/confirm-dialog.module';
import {MainComponent} from './main.component';
import {TenantsService} from './administration/tenant-settings/services/tenants.service';
import {BrowserReloadComponent} from '../shared/browser-reload/browser-reload.component';
import {VersionCheckService} from '../shared/services/version-check.service';
import {RouterTabModule} from '../shared/router-tab/router-tab.module';
import {OnLoadErrorStateMatcher} from '../shared/onLoadErrorStateMatcher';

@NgModule({
  declarations: [
    MainComponent,
    BrowserReloadComponent
  ],
  imports: [
    RouterModule,
    MatButtonModule,
    MatDialogModule,
    MatSidenavModule,

    // Theme modules
    FuseSharedModule,
    FuseThemeOptionsModule,
    FuseSidebarModule,
    AppContentModule,
    AppNavbarModule,

    // Custom modules
    MainRoutingModule,
    ConfirmDialogModule,
    RouterTabModule
  ],

  providers: [
    VersionCheckService,
    TenantsService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      }
    },
    {provide: ErrorStateMatcher, useClass: OnLoadErrorStateMatcher}
  ],
  exports: [
    MainComponent
  ]
})

export class MainModule {}
