import {Component, OnInit} from '@angular/core';

import {fuseAnimations} from '@fuse/animations';
import {FuseConfigService} from '@fuse/services/config.service';
import {UserService} from '../services/user.service';

@Component({
  selector: 'app-unauthenticated',
  templateUrl: './unauthenticated.component.html',
  styleUrls: ['./unauthenticated.component.scss'],
  animations: fuseAnimations
})
export class UnauthenticatedComponent implements OnInit {

  constructor(
    private fuseConfig: FuseConfigService,
    private userService: UserService
  ) {}

  ngOnInit() {

    this.fuseConfig.setConfig({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });
  }

  onLogout() {
    this.userService.logOut();
  }
}
