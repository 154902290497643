import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {FuseSharedModule} from '@fuse/shared.module';

import {AppContentComponent} from 'app/main/content/content.component';

@NgModule({
  declarations: [
    AppContentComponent
  ],
  imports: [
    RouterModule,
    FuseSharedModule,
  ],
  exports: [
    AppContentComponent
  ]
})
export class AppContentModule {}
