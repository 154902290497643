import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';

import {RolesService} from '../services/roles.service';
import {AuthService} from '../services/auth.service';
import {UserService} from '../services/user.service';
import {Tenant} from '../models/tenant';
import {Region} from '../models/region';
import {UsersService} from '../../main/administration/user-profiles/services/usersApi.service';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss'],
  animations: fuseAnimations
})
export class SessionComponent implements OnInit {
  lockForm: FormGroup;
  lockFormErrors: { username: {}, password: {} };
  wrongLoginDetails: boolean;
  username: string;
  activeRegion: Region;
  activeTenant: Tenant;
  showLogo: boolean;

  constructor(
    public snackBar: MatSnackBar,
    private fuseConfig: FuseConfigService,
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private authAPI: AuthService,
    private usersAPI: UsersService,
    private rolesAPI: RolesService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {

    this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape
    ]).subscribe(result => {
      this.showLogo = !result.matches;
    });

    this.fuseConfig.setConfig({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });

    this.lockFormErrors = {
      username: {},
      password: {}
    };

    this.wrongLoginDetails = false;
    this.username = this.userService.getUserData()['username'];
    this.lockForm = this.formBuilder.group({
      username: [
        [this.userService.getUserData()['username']], Validators.required
      ],
      password: ['', Validators.required]
    });

    this.activeRegion = JSON.parse(localStorage.getItem('activeRegion'));
    this.activeTenant = JSON.parse(localStorage.getItem('activeTenant'));

    // remove user data from storage
    this.userService.logOut(true);

    this.lockForm.valueChanges.subscribe(() => {
      this.onLockFormValuesChanged();
    });
  }

  onLockFormValuesChanged() {
    for (const field in this.lockFormErrors) {
      if (!this.lockFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.lockFormErrors[field] = {};

      // Get the control
      const control = this.lockForm.get(field);

      if (control && control.dirty && !control.valid) {
        this.lockFormErrors[field] = control.errors;
      }
    }
  }

  onLogin() {
    return new Promise(() => {

      this.authAPI.authLoginPost({
        username: this.lockForm.value['username'],
        password: this.lockForm.value['password']
      })
        .subscribe(
          response => {
            localStorage.setItem('userdata_tokens', JSON.stringify(response));

            this.usersAPI.profileGet()
              .subscribe(
                responseResult => {
                  localStorage.setItem('activeRegion', JSON.stringify(this.activeRegion));
                  localStorage.setItem('activeTenant', JSON.stringify(this.activeTenant));
                  this.userService.logIn(responseResult, this.activeTenant);
                  this.rolesAPI.rolesGet()
                    .subscribe(
                      (result) => {
                        localStorage.setItem('permissions', JSON.stringify(result));
                        this.router.navigateByUrl(sessionStorage.getItem('last-visited'))
                          .then(
                            () => {
                              sessionStorage.removeItem('last-visited');
                            }
                          );
                      },
                      error => {
                        if (error) {
                          this.snackBar.open(error.message, '', {
                            panelClass: 'mat-red-500-bg'
                          });
                        }
                      }
                    );
                },
                () => {
                  this.lockForm.reset();
                  this.wrongLoginDetails = true;
                }
              );
          },
          () => {
            this.lockForm.controls['password'].reset();
            this.wrongLoginDetails = true;
          }
        );
    });
  }
}
