export const locale = {
  lang: 'en',
  data: {
    'NAV': {
      'ADMIN_MENU': {
        'TITLE': 'Admin menu'
      },
      'PAGES_MENU': {
        'TITLE': 'Pages menu'
      },
      'ADMINISTRATION': 'Administration',
      'TENANT_SETTINGS': {
        'TITLE': 'Tenant settings',
        'GENERAL': {
          'TITLE': 'General settings'
        },
        'BANNER': {
          'TITLE': 'Banner settings'
        },
        'AMAZON_SETTINGS': {
          'TITLE': 'Amazon settings'
        }
      },
      'IMAGES': {
        'TITLE': 'Library images',
        'SANDBOX': {
          'TITLE': 'Sandbox'
        },
        'AWAITING': {
          'TITLE': 'Awaiting approval'
        },
        'PUBLISHED': {
          'TITLE': 'Published',
          'UP_TO_DATE': {
            'TITLE': 'Published up to date (+13 from GMT)'
          },
          'IN_FUTURE': {
            'TITLE': 'Published in future'
          }
        },
        'NEW-ILLUSTRATION': {
          'TITLE': 'Add new Illustration',
        },
        'EDIT-ILLUSTRATION': {
          'TITLE': 'Edit Illustration',
        },
        'CATEGORIES': {
          'TITLE': 'Library image categories'
        },
        'REORDER': {
          'TITLE': 'Image reordering'
        }
      },
      'BANNERS': {
        'TITLE': 'Banners',
      },
      'BUNDLES': {
        'TITLE': 'Bundles',
      },
      'CONTENT-POPUP': {
        'TITLE': 'Content popup',
      },
      'MODERATION': {
        'TITLE': 'Moderation tools',
        'COMMENTS' : {
          'TITLE': 'Comments',
          'ALL': {
            'TITLE': 'All'
          },
          'HIDDEN': {
            'TITLE': 'Hidden'
          }
        },
        'LIVE-FEED': {
          'TITLE': 'Live feed'
        },
        'WEB-PURIFY': {
          'TITLE': 'Web purify'
        },
        'GALLERY-SEARCH': {
          'TITLE': 'Gallery search'
        },
      },
      'NOTIFICATIONS': {
        'TITLE': 'Push notifications',
      },
      'MOBILE-APPS-VERSIONS': {
        'TITLE': 'Mobile apps versions'
      },
      'IN-APP-USERS': {
        'TITLE': 'In-app users',
        'USERS': {
          'TITLE': 'In-app users'
        },
        'ACTION-HISTORY': {
          'TITLE': 'Admin action history'
        }
      },
      'USERS': {
        'TITLE': 'Users'
      },
      'USER_MENU': {
        'TITLE': 'My Profile',
        'LOGOUT': {
          'TITLE': 'Logout'
        }
      }
    }
  }
};
