import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';
import {ApiConfigurationService} from './api-configuration.service';
import {CustomHttpUrlEncodingCodec} from '../encoder';


@Injectable()
export class FileUploadService {

  protected basePath = this.apiConfiguration.basePath();
  protected apiVersion = this.apiConfiguration.apiVersion();
  protected gatewayPrefix = this.apiConfiguration.gatewayPrefix();
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected httpOptions: object;

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected httpClient: HttpClient,
    private apiConfiguration: ApiConfigurationService
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }

    this.httpOptions = {
      headers: this.apiConfiguration.setHeader(this.defaultHeaders, this.configuration),
      withCredentials: this.configuration.withCredentials
    };
  }

  public fetchAwsPresignedUrl(fileName?: string, type?: string, action?: string, isTemp?: boolean): Observable<{}> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    if (fileName !== undefined) {
      queryParameters = queryParameters.set('filename', <any>fileName);
    }

    if (type !== undefined && type !== 'bundle' ) {
      queryParameters = queryParameters.set('type', type);
    }

    if (action !== undefined) {
      if (action === 'has') {
        queryParameters = queryParameters.set('action', 'HasObject');
      } else if (action === 'get') {
        queryParameters = queryParameters.set('action', 'GetObject');
      } else if (action === 'put') {
        queryParameters = queryParameters.set('action', 'PutObject');
      } else if (action === 'delete') {
        queryParameters = queryParameters.set('action', 'DeleteObject');
      }
    }

    if (isTemp) {
      queryParameters = queryParameters.set('temp', 'true');
    }

    const httpOptionsExtended = Object.assign({}, this.httpOptions);
    httpOptionsExtended['params'] = queryParameters;

    if (type === 'bundle') {
      // TODO: remove after testing
      // this.basePath = 'http://dev.dev.dev-api-library.development.recolor.com';

      // httpOptionsExtended['headers'] = httpOptionsExtended['headers'].append('isLibraryApi', 'true');
      return this.httpClient.post<{}>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}category-bundle/image/presigned`, null, httpOptionsExtended);
    }

    return this.httpClient.post<{}>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}file/presigned`, null, httpOptionsExtended);
  }

  public callToAws(url?: string, action?: string) {
    let headers = this.defaultHeaders;

    headers = headers.append('isAws', 'true');

    return this.httpClient.request(action, url, {headers: headers});
  }

  public uploadToAws(url?: string, action?: string, file?: string, contentType?: string) {
    let headers = this.defaultHeaders;

    headers = headers.append('isAws', 'true');
    if (contentType !== '' && contentType !== null && contentType !== undefined) {
      headers = headers.append('Content-Type', contentType);
    }

    return this.httpClient.request(action, url, {body: file, headers: headers});
  }

  public deleteFromAws(url?: string, action?: string, file?: string) {
    let headers = this.defaultHeaders;

    headers = headers.append('isAws', 'true');

    return this.httpClient.request(action, url, {headers: headers});
  }

  public validateFileAfterAwsUpload(fileName?: string, banner?: boolean, isTemp?: boolean): Observable<{}> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    if (fileName !== undefined) {
      queryParameters = queryParameters.set('filename', <any>fileName);
    }

    if (banner) {
      queryParameters = queryParameters.set('type', 'banner');
    } else {
      queryParameters = queryParameters.set('type', 'illustration');
    }

    if (isTemp !== undefined) {
      queryParameters = queryParameters.set('temp', 'true');
    }

    const httpOptionsExtended = Object.assign({}, this.httpOptions);
    httpOptionsExtended['params'] = queryParameters;

    return this.httpClient.post<{}>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}file/verify`, null, httpOptionsExtended);
  }

}
