import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {UserService} from './services/user.service';
import {PermissionsService} from './services/permissions.service';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private user: UserService,
    private permissionsService: PermissionsService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.user.getUserData();

    if (user && !user.super_admin) {

      const userRole = this.permissionsService.getPermissions(user);
      const permissions = route.data['permission'];

      if (this.isEmpty(userRole)) {
        this.router.navigate(['/auth/unauthenticated']);
      }

      if (permissions !== undefined) {
        for (let i = 0; i < permissions.length; i++) {
          if (userRole[permissions[i]]) {
            return true;
          } else if (state.url === '/bundles/new' && userRole['category-bundle_list']) {
            this.router.navigate(['/bundles']);
            return false;
          } else if (userRole['image_add']) {
            this.router.navigate(['/images/sandbox']);
            return false;
          } else if (userRole[environment.apiVersion + '_user_view']) {
            this.router.navigate(['/in-app/users']);
            return false;
          } else if (userRole[environment.apiVersion + '_moderation_image_list']) {
            this.router.navigate(['/moderation-tool/tools']);
            return false;
          }
        }
      } else {
        return true;
      }

      this.router.navigate(['/auth/unauthenticated']);
      return false;
    } else if (user && user.super_admin) {
      return true;
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}
