import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {CanDeactivateGuard} from './shared/canDeactivate.guard';

const appRoutes: Routes = [
  {path: 'auth', loadChildren: './auth/auth.module#AuthModule'},
  {path: '', loadChildren: './main/main.module#MainModule'},
  {path: '**', redirectTo: '/not-found'}

];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules})
  ],
  providers: [
    AuthGuard,
    CanDeactivateGuard
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {}
