import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../../../shared/encoder';
import {Observable} from 'rxjs';

import {BASE_PATH} from '../../../shared/variables';
import {Configuration} from '../../../shared/configuration';
import {ApiConfigurationService} from '../../../shared/services/api-configuration.service';
import {ApiResponse} from '../../../shared/models/apiResponse';
import {CountResponse} from '../../../shared/models/countResponse';
import {Notification} from '../models/notification';

@Injectable()
export class PushNotificationsService {

  protected basePath = this.apiConfiguration.basePath();
  protected apiVersion = this.apiConfiguration.apiVersion();
  protected gatewayPrefix = this.apiConfiguration.gatewayPrefix();
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected httpOptions: object;

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected httpClient: HttpClient,
    private apiConfiguration: ApiConfigurationService
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }

    this.httpOptions = {
      headers: this.apiConfiguration.setHeader(this.defaultHeaders, this.configuration),
      withCredentials: this.configuration.withCredentials
    };
  }

  /**
   * Gets all push notifications
   * Gets all notifications
   * @param page Pagination page
   * @param pageSize Pagination page size
   * @param count If set to true will return count of fetched collection
   * @param filterBy Will return filtered collection
   */
  public cGetNotification(page?: number, pageSize?: number, count?: boolean, filterBy?: string): Observable<Array<Notification> | CountResponse> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (page !== undefined) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (pageSize !== undefined) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }

    if (count !== undefined) {
      queryParameters = queryParameters.set('count', <any>count);
    }
    if (filterBy !== undefined) {
      queryParameters = queryParameters.set('filterBy', <any>filterBy);
    }

    const httpOptionsExtended = Object.assign({}, this.httpOptions);
    httpOptionsExtended['params'] = queryParameters;

    return this.httpClient.get<Array<Notification>>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}notification`, httpOptionsExtended);
  }

  /**
   * Creates a push notifications
   * Creates a notifications
   * @param body Push Notification object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cPostNotification(body: Notification, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling cPostNotification.');
    }

    return this.httpClient.post<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}notification`, body, this.httpOptions);
  }

  /**
   * Deletes a Push Notification
   * Deletes a Push Notification
   * @param notificationId ID (name) of the Push Notification that needs to be deleted
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deletePushNotification(notificationId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse> {
    if (notificationId === null || notificationId === undefined) {
      throw new Error('Required parameter notificationId was null or undefined when calling deletePushNotification.');
    }

    return this.httpClient.delete<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}notification/${encodeURIComponent(String(notificationId))}`, this.httpOptions);
  }

  /**
   * Get Push Notification by ID
   * Get Push Notification by ID
   * @param notificationId ID (name) of the Push Notification that needs to be fetched
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPushNotification(notificationId: number, observe?: 'body', reportProgress?: boolean): Observable<Notification> {
    if (notificationId === null || notificationId === undefined) {
      throw new Error('Required parameter notificationId was null or undefined when calling getPushNotification.');
    }

    return this.httpClient.get<Notification>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}notification/${encodeURIComponent(String(notificationId))}`, this.httpOptions);
  }

  /**
   * Updates a Push Notification
   * Updates a Push Notification
   * @param notificationId ID (name) of the Push Notification that needs to be edited
   * @param body Push Notification object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchPushNotification(notificationId: string, body: Notification, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse> {
    if (notificationId === null || notificationId === undefined) {
      throw new Error('Required parameter notificationId was null or undefined when calling patchPushNotification.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling patchPushNotification.');
    }

    return this.httpClient.patch<ApiResponse>(`${this.basePath}/library${this.gatewayPrefix}${this.apiVersion}notification/${encodeURIComponent(String(notificationId))}`,
      body, this.httpOptions);
  }

}
