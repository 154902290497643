import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';

import {FusePerfectScrollbarDirective} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {NavigationService} from '../../navigation/services/navigation.service';
import {UserService} from '../../auth/services/user.service';
import {Tenant} from '../../auth/models/tenant';
import {User} from '../administration/user-profiles/models/user';
import {TenantsService} from '../administration/tenant-settings/services/tenants.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppNavbarComponent implements OnInit, OnDestroy {
  private fusePerfectScrollbar: FusePerfectScrollbarDirective;
  tenants: Array<Tenant>;
  selectedTenant: {};
  tenantIsSelected: boolean;
  showLoadingBar: boolean;
  username: string;
  getLoggedInUserData: Array<User>;

  @ViewChild(FusePerfectScrollbarDirective) set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this.fusePerfectScrollbar = theDirective;

    this.navigationServiceWatcher =
      this.navigationService.onItemCollapseToggled.subscribe(() => {
        this.fusePerfectScrollbarUpdateTimeout = setTimeout(() => {
          this.fusePerfectScrollbar.update();
        }, 310);
      });
  }

  @ViewChild('user_navigation') userNavigationElem;

  @Input() layout;
  navigationServiceWatcher: Subscription;
  fusePerfectScrollbarUpdateTimeout;
  navigation: {};
  userNavigation: Object[];
  tenant: Tenant;

  constructor(
    private sidebarService: FuseSidebarService,
    private navigationService: FuseNavigationService,
    private dynamicNavigationService: NavigationService,
    private tenantsService: TenantsService,
    private router: Router,
    private translate: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.tenants = [];
    this.tenantIsSelected = false;
    this.username = '';
    this.tenant = {logo: 'assets/images/logos/recolor_logo.png', name: ''};
    // Navigation data
    this.navigation = this.dynamicNavigationService.onNavigationCreate();

    this.userNavigation = [];

    this.tenantsService.getActiveTenant();
    this.tenantsService.selectedTenant.subscribe(data => {
      if (data !== null) {
        this.tenant = data;
      }
    });

    this.getLoggedInUserData = this.userService.getUserData();

    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this.showLoadingBar = true;
        }
        if (event instanceof NavigationEnd) {
          this.showLoadingBar = false;
        }
      });

    if (this.getLoggedInUserData) {
      this.username = this.getLoggedInUserData['username'];
      this.userNavigation.push({
        'id': 'user_menu',
        'title': 'My Profile',
        'translate': 'NAV.USER_MENU.TITLE',
        'type': 'collapse',
        'icon': 'account_circle',
        'children': [
          {
            'id': 'user_profile',
            'title': this.username,
            'translate': this.username,
            'type': 'item',
            'url': '/profile',
            'function': () => {
              this.toggleSidebarOpened('navbar');
            }
          },
          {
            'id': 'user_logout',
            'title': 'Logout',
            'translate': 'NAV.USER_MENU.LOGOUT.TITLE',
            'type': 'item',
            'icon': 'exit_to_app',
            'function': () => {
              this.onLogout();
              this.toggleSidebarOpened('navbar');
            }
          }
        ]
      });
    }

    if (this.getLoggedInUserData !== null) {
      let selectedTenantHtml = '';
      const tenantsGroupNav = [];
      this.tenantsService.getAvailableTenants()
        .then(
          (tenantList: Tenant[]) => {
            for (let i = 0; i < tenantList.length; i++) {
              tenantList[i]['logo'] = 'assets/images/logos/recolor_logo.png';
            }
            this.tenants = tenantList;

            for (let i = 0; i < tenantList.length; i++) {
              const tenantLogo = '<img class="logo-small mr-16" src="' + tenantList[i]['logo'] + '">' +
                '<span class="iso">' + tenantList[i]['name'].toUpperCase() + '</span>';
              tenantsGroupNav.push(
                {
                  'id': tenantList[i]['name'].toLowerCase().replace(' ', '_'),
                  'title': tenantList[i]['name'].toUpperCase(),
                  'translate': tenantList[i]['name'].toUpperCase(),
                  'rawHtml': tenantLogo,
                  'type': 'item',
                  'function': () => this.setTenant(tenantList[i])
                }
              );
            }

            this.tenantsService.selectedTenant
              .subscribe(
                data => {
                  this.selectedTenant = data;
                  if (this.selectedTenant['id'] !== undefined) {
                    this.tenantIsSelected = true;
                    selectedTenantHtml = '<img class="logo-small mr-8" src="' + this.selectedTenant['logo'] + '" >' +
                      '<span class="iso text-uppercase tenant-title">' + this.selectedTenant['name'].toUpperCase() + '</span>';
                    if (this.userNavigation[1] === undefined) {
                      if (this.tenants.length > 1) {
                        this.userNavigation.push({
                          'id': 'tenant_menu',
                          'title': this.selectedTenant['name'].toUpperCase(),
                          'translate': this.selectedTenant['name'].toUpperCase(),
                          'rawHtml': selectedTenantHtml,
                          'type': 'collapse',
                          'children': tenantsGroupNav
                        });
                      } else if (this.tenants.length === 1) {
                        this.userNavigation.push({
                          'id': 'tenant_menu',
                          'title': this.selectedTenant['name'].toUpperCase(),
                          'translate': this.selectedTenant['name'].toUpperCase(),
                          'rawHtml': selectedTenantHtml,
                          'type': 'collapse'
                        });
                      }
                    }
                  }
                });
          }
        );
    }
  }

  ngOnDestroy() {

    if (this.fusePerfectScrollbarUpdateTimeout) {
      clearTimeout(this.fusePerfectScrollbarUpdateTimeout);
    }

    if (this.navigationServiceWatcher) {
      this.navigationServiceWatcher.unsubscribe();
    }
  }

  toggleSidebarOpened(key) {
    this.sidebarService.getSidebar(key).toggleOpen();
  }

  toggleSidebarFolded(key) {
    this.sidebarService.getSidebar(key).toggleFold();
  }

  setTenant(tenant) {
    // Set the selected tenant for toolbar
    this.selectedTenant = tenant;
    this.getLoggedInUserData['tenant'] = tenant;
    localStorage.setItem('userdata', JSON.stringify(this.getLoggedInUserData));

    this.tenantsService.getActiveTenant('onChange');

    // Use the selected language for translations
    this.translate.use(tenant.id);
    this.tenantIsSelected = true;
  }

  onLogout() {
    this.tenantsService.discardSelected();
    this.userService.logOut();
  }
}
